import dayjs, { Dayjs } from "dayjs"
import isBetween from "dayjs/plugin/isBetween.js"
import "dayjs/locale/ja"

type DateFormat =
  | "YYYY年MM月DD日"
  | "YYYY年M月D日"
  | "YYYY年M月D日 HH:mm"
  | "YYYY年MM月DD日 HH:mm"
  | "YYYY年MM月DD日 HH:mm:ss"
  | "YYYY-MM-DD"
  | "YYYY-MM-DD HH:mm"
  | "YYYY-MM-DD HH:mm:ss"
  | "YYYY.MM.DD"
  | "YYYY/MM/DD"
  | "YYYY/MM/DD HH:mm"
  | "YYYY/MM/DD HH:mm:ss"
  | "YYYY/M/D (ddd)"
  | "YYYY/M/D (ddd) HH:mm"

dayjs.extend(isBetween) // eslint-disable-line

/**
 * 日付をフォーマットする
 * @param unixtime UNIXタイムスタンプ
 * @param format DateFormatのフォーマット
 * @returns フォーマットされた日付
 */
export const formatUnixTime = (unixtime: number | null | undefined | string, format: DateFormat): string => {
  dayjs.locale("ja") // eslint-disable-line
  return unixtime != null ? dayjs(unixtime).format(format) : ""
}

/**
 * YYYY年MM月DD日 にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDate = (unixtime: number | null | undefined | string): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日") : ""
}
/**
 * YYYY年MM月DD日 HH:mm:ss にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateHour = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日 HH:mm:ss") : ""
}
/**
 * YYYY-MM-DD HH:mm:ss にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateHourIso = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY-MM-DD HH:mm:ss") : ""
}
/**
 * YYYY-MM-DD にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateIso = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY-MM-DD") : ""
}
/**
 * YYYY年MM月DD日 HH:mm にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateWithoutSeconds = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日 HH:mm") : ""
}

/**
 * 現在のUnixTimeを取得する
 **/
export const getNowUnixTime = (): number => {
  return dayjs().valueOf()
}
/**
 * 使用期限を当日の23:59:59にする
 * @param expirationDays 期限までの日数。負の値を指定した場合は、現在日の前日の23:59:59の日付が設定されます。
 * @returns {number} 使用期限のUNIXタイムスタンプ（ミリ秒）
 * */
export const getExpirationUnixTime = (expirationDays: number): number => {
  const expirationDate = dayjs().add(expirationDays, "day").endOf("day")
  return expirationDate.unix() * 1000
}

/**
 * 使用期限を指定した日付の23:59:59にする
 * @param expirationDate 期限日
 * @returns {number} 使用期限のUNIXタイムスタンプ（ミリ秒）
 * */
export const mileageExpiredUnixtime = (): number => dayjs().add(1, "year").endOf("month").unix() * 1000

/**
 * コンビニ受け取り用の日付フォーマット関数
 * YYYY-MM-DD dddd -> YYYY-MM-DD 前後 に変換
 */
export const formatKonbiniShippingDate = (date?: string): string | null => {
  if (!date) return null
  const match = date.match(/^\d{4}-\d{2}-\d{2}/)
  return match ? `${match[0]} 前後` : null
}

/**
 * 日付が指定の範囲内にあるかを判定する関数
 * 判定日を指定しない場合は現在の日付を使用する
 */
export const isWithinDateRange = ({
  start,
  end,
  date = dayjs(),
}: {
  /** 開始日 */
  start: string | Dayjs
  /** 終了日 */
  end: string | Dayjs
  /** 判定する日付（省略時は現在の日付） */
  date?: string | Dayjs
}): boolean => {
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  const targetDate = dayjs(date)

  return targetDate.isBetween(startDate, endDate, "day", "[]")
}

/**
 * unixtimeに変換する
 */
export const convertUnixTime = (date: string): number => {
  return dayjs(date).unix() * 1000
}
