export const LayoutType = {
  blank: "空白",
  top_section: "キャッチコピーとテキスト", // 廃止
  catchphrase: "キャッチフレーズ",
  head_text: "見出し1",
  text: "テキスト",
  editor_text: "テキストエディタ",
  image_and_text: "テキストと画像",
  video_and_text: "テキストと動画",
  banner_with_text: "テキストつきバナー",
  panel_text: "パネルとテキスト", // 廃止
  block_to_detail: "詳細への画像ブロック",
  small_image_text_to_detail: "詳細への画像とテキストブロック小",
  block_link: "ブロックリスト",
  breadcrumbs: "パンくずリスト",
  accordion: "アコーディオン",
  anchor_link: "アンカーリンク",
  image: "画像",
  image_scroll: "画像(横並び)", // 廃止
  image_slide: "画像スライド",
  image_slide_big: "画像スライド大(FVスライド)",
  large_slide_contents: "スライドショー中",
  video: "動画",
  cart: "カート",
  multi_cart: "同時購入カート",
  group_wrapping_multi_cart: "まとめてラッピング同時購入カート",
  custom_image_multi_cart: "カスタム画像同時購入カート",
  embroidery_limited_cart: "刺繍限定カート", // 期間限定
  product_list: "商品一覧",
  no_validation_product_list: "商品一覧（バリデーションなし）",
  recommend_product_list: "おすすめ商品一覧(旧)", // 廃止
  recommend_product_list_v2: "商品比較",
  recommend_product_list_by_price: "価格別おすすめ商品一覧",
  recommend_product_list_by_scene: "シーン別おすすめ商品一覧",
  product_browsing_history: "閲覧履歴",
  ranking_view: "ランキングビュー",
  thumbnail_list: "サムネイル一覧", // 廃止
  review_list: "レビュー一覧",
  expert_list: "エキスパート一覧",
  point: "ポイント",
  evaluation: "評価", // 廃止
  discount_contents: "割引セクション", // 廃止
  banner: "バナー",
  modal_banner: "モーダルバナー",
  modal_banner_image: "モーダルバナー(画像)",
  bottom_blur_card_list: "クリアカード",
  message_card: "メッセージカード", // 廃止
  news_list: "ニュース",
  journal_list: "記事一覧",
  interview_list: "インタビュー記事一覧", // 廃止
  categories: "カテゴリ一覧",
  tab_group: "タブグループ",
  accordion_group: "アコーディオングループ",
} as const
export type LayoutType = keyof typeof LayoutType

export const FontWeight = {
  "": "---",
  normal: "通常",
  bold: "太字",
  bolder: "親要素より太字",
  lighter: "親要素より細字",
} as const
export type FontWeight = keyof typeof FontWeight

export const TextAlign = {
  "": "---",
  left: "左",
  center: "中央",
  right: "右",
} as const
export type TextAlign = keyof typeof TextAlign

// カラム数
export const ImagePCColumnType = [1, 1.5, 2, 3, 4, 5] as const
export type ImagePCColumnType = (typeof ImagePCColumnType)[number]
export const ImageSPColumnType = [1, 1.5, 2, 3] as const
export type ImageSPColumnType = (typeof ImageSPColumnType)[number]

// 表示デバイス
export const DisplayType = ["all", "pc", "sp"] as const
export type DisplayType = (typeof DisplayType)[number]

// 子パーツが設定可能なパーツ
export const childLayoutTypeList = ["tab_group", "accordion_group"] as const

// 廃止パーツ
export const ignoreLayoutType = [
  "image_scroll",
  "recommend_product_list",
  "discount_contents",
  "evaluation",
  "message_card",
  "thumbnail_list",
  "top_section",
  "panel_text",
  "interview_list",
  "embroidery_limited_cart", // 刺繍限定カートを使用する際にリストから削除
]
