import { useConfirmDialogStore } from "~/stores/confirmDialog"

export const useConfirmDialog = () => {
  const store = useConfirmDialogStore()
  const proceed = (message: string, buttonText?: { agree?: string; disagree?: string }): Promise<boolean> => {
    store.show(message, buttonText)
    return new Promise((resolve) => {
      watch(
        () => store.isShow,
        (isShow) => {
          if (!isShow) resolve(store.isAgree)
        },
      )
    })
  }
  return { proceed }
}
