// middleware リダイレクト用URLリスト

export type RedirectUrl = {
  target_url: string
  goto_url: string
}

/**
 * インタビューURLを snake_case -> kebab-case の変更に伴う、旧URL -> 新URLのリダイレクトリスト
 * ref. https://tential.slack.com/archives/C013KEPFLSV/p1718074209827759?thread_ts=1716264665.821209&cid=C013KEPFLSV
 */
const interviewUrls: RedirectUrl[] = [
  {
    target_url: "/interviews/cmag_kisuinakazawa",
    goto_url: "/interviews/cmag-kisuinakazawa",
  },
  {
    target_url: "/interviews/cmag_netarotsuneki",
    goto_url: "/interviews/cmag-netarotsuneki",
  },
  {
    target_url: "/interviews/cmag_annatsuchiya",
    goto_url: "/interviews/cmag-annatsuchiya",
  },
  {
    target_url: "/interviews/cmag_tsuyoshiwada",
    goto_url: "/interviews/cmag-tsuyoshiwada",
  },
  {
    target_url: "/interviews/cmag_fumioyonezawa",
    goto_url: "/interviews/cmag-fumioyonezawa",
  },
  {
    target_url: "/interviews/cmag_hirokazuibata",
    goto_url: "/interviews/cmag-hirokazuibata",
  },
  {
    target_url: "/interviews/cmag_chenelle",
    goto_url: "/interviews/cmag-chenelle",
  },
  {
    target_url: "/interviews/cmag_renatakeshita",
    goto_url: "/interviews/cmag-renatakeshita",
  },
]
/**
 * リダイレクト用URLリスト target_url にはワイルドカードも使用可能
 */
export const RedirectUrls: RedirectUrl[] = [
  {
    target_url: "/products/bakune-blanket",
    goto_url: "/sleep/sleep-accessory/bakune-blanket",
  },
  {
    target_url: "/products/bakune-dry-ladies-long",
    goto_url: "/sleep/recovery-wear/bakune-dry-ladies-long-set",
  },
  {
    target_url: "/products/bakune-dry-ladies",
    goto_url: "/sleep/recovery-wear/bakune-dry-ladies-set",
  },
  {
    target_url: "/products/bakune-dry-long",
    goto_url: "/sleep/recovery-wear/bakune-dry-long-slimpants-set",
  },
  {
    target_url: "/products/bakune-dry",
    goto_url: "/sleep/recovery-wear/bakune-dry-set",
  },
  {
    target_url: "/products/bakune-eye-mask",
    goto_url: "/sleep/sleep-accessory/bakune-eye-mask",
  },
  {
    target_url: "/products/bakune-haramaki",
    goto_url: "/sleep/sleep-accessory/bakune-haramaki",
  },
  {
    target_url: "/products/bakune-hug",
    goto_url: "/sleep/pillow/bakune-hug",
  },
  {
    target_url: "/products/bakune-makura",
    goto_url: "/sleep/pillow/bakune-makura",
  },
  {
    target_url: "/products/bakune-neckwarmer",
    goto_url: "/sleep/sleep-accessory/bakune-neckwarmer",
  },
  {
    target_url: "/products/bakune-pajamas-half",
    goto_url: "/sleep/recovery-wear/bakune-pajamas-half-set",
  },
  {
    target_url: "/products/bakune-pajamas",
    goto_url: "/sleep/recovery-wear/bakune-pajamas-long-set",
  },
  {
    target_url: "/products/bakune-sheets",
    goto_url: "/sleep/bedding/bakune-sheets",
  },
  {
    target_url: "/products/bakune-warm",
    goto_url: "/sleep/recovery-wear/bakune-warm-set",
  },
  {
    target_url: "/products/bakune",
    goto_url: "/sleep/recovery-wear/bakune-set",
  },
  {
    target_url: "/products/cure-socks",
    goto_url: "/sleep/sleep-accessory/compression-socks",
  },
  {
    target_url: "/products/golf-socks",
    goto_url: "/foot-wear/socks/golf-socks",
  },
  {
    target_url: "/products/insole-business-lite",
    goto_url: "/foot-wear/insole/insole-business-lite",
  },
  {
    target_url: "/products/insole-business",
    goto_url: "/foot-wear/insole/insole-business",
  },
  {
    target_url: "/products/insole-golf-lite",
    goto_url: "/foot-wear/insole/insole-golf-lite",
  },
  {
    target_url: "/products/insole-golf",
    goto_url: "/foot-wear/insole/insole-golf-carbon",
  },
  {
    target_url: "/products/insole-junior",
    goto_url: "/foot-wear/insole/insole-junior",
  },
  {
    target_url: "/products/insole-lite",
    goto_url: "/foot-wear/insole/insole-lite",
  },
  {
    target_url: "/products/insole-running",
    goto_url: "/foot-wear/insole/insole-running",
  },
  {
    target_url: "/products/insole",
    goto_url: "/foot-wear/insole/insole-standard",
  },
  {
    target_url: "/products/mask-spray",
    goto_url: "/work-support/mask/mask-spray",
  },
  {
    target_url: "/products/mask",
    goto_url: "/work-support/mask/mask",
  },
  {
    target_url: "/products/migaru",
    goto_url: "/work-support/work-wear/migaru-pull-hoodie",
  },
  {
    target_url: "/products/migaru_poloshirts",
    goto_url: "/work-support/work-wear/migaru-dry-polo-shirts",
  },
  {
    target_url: "/products/migaru_tshirts",
    goto_url: "/work-support/work-wear/migaru-dry-t-shirts",
  },
  {
    target_url: "/products/movon",
    goto_url: "/work-support/protein/movon",
  },
  {
    target_url: "/products/recoverysandal_conditioning_flipflop",
    goto_url: "/foot-wear/sandal/recoverysandal-conditioning-flipflop",
  },
  {
    target_url: "/products/recoverysandal_relax_flipflop",
    goto_url: "/foot-wear/sandal/recoverysandal-relax-flipflop",
  },
  {
    target_url: "/products/recoverysandal_relax_slide",
    goto_url: "/foot-wear/sandal/recoverysandal-relax-slide",
  },
  {
    target_url: "/products/shoe-spray",
    goto_url: "/foot-wear/foot-accessory/shoe-spray",
  },
  {
    target_url: "/products/socks",
    goto_url: "/foot-wear/socks/business-socks",
  },
  {
    target_url: "/products/tential_totonou_bathsalt",
    goto_url: "/work-support/bath-salt/totonou-bathsalt",
  },
  {
    target_url: "/products/bakune-dotz",
    goto_url: "/sleep/sleep-accessory/bakune-dotz",
  },
  {
    target_url: "/products/bakune-duvet-cover",
    goto_url: "/sleep/bedding/bakune-duvet-cover",
  },
  {
    target_url: "/products/bakune-ladies",
    goto_url: "/sleep/recovery-wear/bakune-ladies-flarepants-set",
  },
  {
    target_url: "/products/migaru-inner-tshirt",
    goto_url: "/work-support/work-wear/migaru-inner-tshirt",
  },
  {
    target_url: "/products/migaru-inner-long-tights",
    goto_url: "/work-support/work-wear/migaru-inner-long-tights",
  },
  {
    target_url: "/products/recoverysandal_relax_lp1",
    goto_url: "/lp/recoverysandal_lp1",
  },
  {
    target_url: "/features/giftwrapping",
    goto_url: "/page/giftwrapping",
  },
  {
    target_url: "/night-conditioning/pillow",
    goto_url: "/night-conditioning/bedding",
  },
  {
    target_url: "/night-conditioning/pillow/*",
    goto_url: "/night-conditioning/bedding",
  },
  {
    target_url: "/sleep/*",
    goto_url: "/night-conditioning",
  },
  {
    target_url: "/foot-wear/*",
    goto_url: "/day-conditioning",
  },
  {
    target_url: "/work-support/*",
    goto_url: "/day-conditioning",
  },
  ...interviewUrls,
]
