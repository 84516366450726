<template>
  <div :class="[$style.root, { [$style.size_error]: isError }]">
    <PagePartsCartPartsSelectSizeButton
      v-for="sku in filterSkuByColors"
      :key="sku.size"
      :selected="selectedSize === sku.size"
      :is-out-of-stock="sku.isOutOfStock"
      :is-e-gift="isEGift"
      @click.stop="clickEvent(sku, false, cartIndex)"
    >
      {{ sku.size }}
    </PagePartsCartPartsSelectSizeButton>
  </div>
</template>

<script setup lang="ts">
import { SkuFragmentFragment } from "~/types/type.generated"

type Sku = Pick<
  SkuFragmentFragment,
  | "color"
  | "size"
  | "color_code"
  | "main_image"
  | "chip_image_url"
  | "stock_quantity"
  | "stock_quantity_limit"
  | "egift_stock_quantity_limit"
  | "stock_reservation_quantity"
  | "net_stock_quantity"
  | "net_stock_quantity_limit"
  | "is_reservation"
>
type Props = {
  cartIndex?: number
  selectedSku?: Sku | null
  selectedSize?: string
  skus: Sku[]
  clickEvent: (sku: Sku, isColor: boolean, cartIndex: number) => void
  isError?: boolean
  isEGift?: boolean
  ignoreStockCheck?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  cartIndex: 0,
  selectedSku: null,
  selectedSize: "",
  skus: () => [],
  isError: false,
  isEGift: false,
  /** 在庫チェックを無効化し常に在庫あり状態の表示を行うか */
  ignoreStockCheck: false,
})

const isOutOfStock = (sku: Sku) => {
  return isSkuOutOfStock(sku)
}

type FilterSkuByColor = Sku & { isOutOfStock: boolean }
const filterSkuByColors = computed<FilterSkuByColor[]>(() => {
  const skusByColor: FilterSkuByColor[] = []
  if (!props.selectedSku) {
    props.skus.forEach((sku) => {
      if (sku.color === "") {
        skusByColor.push({
          ...sku,
          isOutOfStock: !props.ignoreStockCheck ? isOutOfStock(sku) : false,
        })
      }
    })
  } else if (typeof props.selectedSku.color === "string" && props.skus) {
    props.skus.forEach((sku) => {
      if (props.selectedSku && props.selectedSku.color === sku.color) {
        skusByColor.push({
          ...sku,
          isOutOfStock: !props.ignoreStockCheck ? isOutOfStock(sku) : false,
        })
      }
    })
  }
  return skusByColor
})
</script>

<style scoped module lang="scss">
.root {
  display: flex;
  flex-wrap: wrap;
  max-width: fit-content;
  gap: 0.5rem;
}
.size_error {
  border: 1px solid $danger;
  border-radius: 5px;
}
</style>
