import { PriceInfoTypeFromSku } from "@tential/ec-gql-schema/types/cart"
/**
 * SKUから価格情報を計算し、税込み価格と税抜き価格を含む情報を返す
 * 元の価格および割引が適用された価格（存在する場合）を考慮して、価格情報を計算します。
 *
 * @returns {PriceInfoTypeFromSku}
 */
export const calcPrice = (
  {
    price,
    discounted_price,
    consumption_tax_percentage,
  }: { price: number; discounted_price?: number; consumption_tax_percentage?: number },
  count = 1,
): PriceInfoTypeFromSku => {
  const taxRate = consumption_tax_percentage || 10
  const originalPrice = price
  const originalPriceInTax = (price * count * (100 + taxRate)) / 100
  const effectivePrice = discounted_price || price
  const effectivePriceInTax = (effectivePrice * count * (100 + taxRate)) / 100
  const effectiveReferencePrice = Math.floor(effectivePriceInTax)
  return {
    original_price: originalPriceInTax,
    original_price_exclude_tax: originalPrice * count,
    original_reference_price: Math.floor(originalPriceInTax),
    original_reference_price_comma: originalPriceInTax.toLocaleString(),
    effective_price: effectivePriceInTax,
    effective_price_exclude_tax: effectivePrice * count,
    effective_reference_price: effectiveReferencePrice,
    effective_reference_price_comma: effectiveReferencePrice.toLocaleString(),
  }
}

/** 税込金額から消費税のみを取得する */
export const priceOnlyTax = (price: number, _tax = 10): number => {
  const tax = (_tax || 10) / 100
  // 消費税抜きの金額を計算
  const basePrice = price / (1 + tax)
  // basePriceを100倍して四捨五入し再び100で割ることで、小数点以下2桁までの精度を確保。
  // これにより、通貨計算の精度を保ちながら、消費税額を正確に求める
  const taxAmount = price - Math.round(basePrice * 100) / 100
  return Math.round(taxAmount * 100) / 100 // 小数点第2位まで
}
