<template>
  <div>
    <!-- 共通モーダル -->
    <OrganismsConfirmDialog />
    <OrganismsToast />

    <CartAddCartModal />

    <!-- クイックビュー -->
    <OrganismsQuickProductViewModal />

    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { useStore } from "~/stores"
import { useCustomMeta } from "~/composables/meta"
import { appendKarteScripts, removeKarteScripts } from "~/utils/functions/karte"
import { crossoverTag } from "~/utils/functions/affiliate"
import { criteoTag } from "~/utils/functions/crto"
import { yahooGeneralTag, yahooRetargetingScript, yahooRetargetingTag } from "~/utils/functions/yahoo"
import { mercariGeneralTag, mercariRetargetingTag } from "~/utils/functions/mercari"
import { fbPixel } from "~/utils/const/facebook"
import { MetaInfo, ScriptPropertySrc, ScriptPropertyText } from "~/types/meta"
import { useAdTrackingCreateMutation, usePublicAdFindOneQuery } from "~/gql/urql.generated"
import { useCart } from "~/composables/useCart"

const store = useStore()
const route = useRoute()
const config = useRuntimeConfig()
const customMeta = useCustomMeta()
const nuxtApp = useNuxtApp()

const { fetchGiftPattern } = useGift()
const { cartState } = useCart()

const { executeMutation: createAdTracking } = useAdTrackingCreateMutation()

const isShowSearchSideBarSP = ref(false)

const closeSearchSideBarSP = () => {
  isShowSearchSideBarSP.value = false
}

const addRouteLog = (BASE_URL: string) => {
  store.addRouteLog({
    url: BASE_URL + route.fullPath,
    stamped_unixtime: dayjs().unix() * 1000,
  })
}

const addTrackingInformation = async (BASE_URL: string) => {
  // 参照先が自サイト以外の場合、トラフィックをセット
  if (!document.referrer.includes(`${BASE_URL}`) && store.trafficParams && !store.trafficParams.full_path) {
    store.setTrafficParams(document.referrer)
  }

  // 広告クエリパラメータがある場合、トラッキング情報をセット
  if (route.query.u) {
    try {
      // 広告トラッキング情報をセット
      store.trackingPost({
        ad_url: typeof route.query?.u === "string" ? route.query.u : String(route.query.u),
        to_url: route.fullPath,
      })

      // 広告情報を取得
      const { data: adResult } = await usePublicAdFindOneQuery({
        variables: {
          filter: {
            ad_url: store.trackingParams.ad_url,
          },
        },
      })

      if (adResult.value?.publicAdFindOne) {
        const adTracking = {
          ad_id: adResult.value.publicAdFindOne.document_id,
          url: store.trackingParams.to_url,
        }
        // 広告トラッキング情報を追加
        await createAdTracking({ record: adTracking })
      }
    } catch {
      console.error("広告トラッキング情報の取得・追加に失敗しました")
    }
  }
}

const { data: newrelic } = await useFetch("/api/newrelic")

const meta = (): MetaInfo => {
  const script: (ScriptPropertyText | ScriptPropertySrc)[] = []

  script.push({ innerHTML: newrelic.value || "" })
  script.push(fbPixel)
  script.push(yahooRetargetingTag)
  script.push(yahooRetargetingScript)
  script.push(yahooGeneralTag)
  script.push(criteoTag)
  script.push(crossoverTag)
  script.push(mercariRetargetingTag)
  script.push(mercariGeneralTag)
  // script.push(ugcCoreTag)
  return customMeta.fetch({
    script,
  })
}

const karteViewEvent = () => {
  const { vueApp } = nuxtApp
  if (vueApp.$nuxt.$router) {
    const isJournalPage = route.fullPath.includes("journals")
    if (!isJournalPage && window.krt && ["production"].includes(config.public.APP_ENV)) {
      window.krt("view")
    }
  }
}

const getCouponCode = () => {
  if (route.query.coupon_code) {
    const coupon_code = typeof route.query.coupon_code === "string" ? route.query.coupon_code : ""
    const expired = dayjs().add(3, "week").unix()
    const payload = {
      couponCode: coupon_code,
      expired,
    }
    store.setCouponCode(payload)
  }
}

const setLocalUserId = () => {
  if (process.client) {
    const localUserId = localStorage.getItem("localUserId")
    if (localUserId) return
    const generateLocalUserId =
      parseInt(
        Math.ceil(Math.random() * Date.now())
          .toPrecision(20)
          .toString(),
      ) +
      "+" +
      Math.random().toString(32).substring(2)

    localStorage.setItem("localUserId", generateLocalUserId)
  }
}
await useAsyncData(async () => await fetchGiftPattern(true))

onMounted(async () => {
  getCouponCode()
  addRouteLog(config.public.BASE_URL)
  await addTrackingInformation(config.public.BASE_URL)
  karteViewEvent()
  appendKarteScripts(route.fullPath)
  setLocalUserId()
})

watch(
  () => isShowSearchSideBarSP.value,
  (value) => {
    if (value) {
      window.history.pushState(null, "", null)
      window.addEventListener("popstate", closeSearchSideBarSP)
    } else {
      window.removeEventListener("popstate", closeSearchSideBarSP)
    }
  },
)

watch(
  () => route.fullPath,
  () => {
    appendKarteScripts(route.fullPath)
    removeKarteScripts(route.fullPath)
    addRouteLog(config.public.BASE_URL)
    karteViewEvent()
    cartState.isShowAddCartModal = false
    cartState.quickViewProductId = ""
  },
)

useHead(meta())
</script>
