import dayjs from "dayjs"
import { LayoutType } from "@tential/ec-gql-schema/master/pageParts"
import { calcPrice } from "@tential/ec-gql-schema/utils/functions/price"
import type { Sku } from "@tential/ec-gql-schema/models/sku"

type SizeRecommendEventArg = {
  product_id: string
  gender: string
  height?: number
  weight?: number
  foot_size?: number
  recommend_size: string
}

export const BannerType = {
  ...LayoutType,
  top_page: "TOPページ",
} as const

export const useGtagEvent = () => {
  const gtag = useGtag()
  const config = useRuntimeConfig()
  const route = useRoute()
  const isProd = config.public.APP_ENV === "production"

  const trackAddToCart = (skus: Sku[]): void => {
    if (!isProd) return

    const items = skus.map((sku) => ({
      item_id: sku.document_id,
      item_name: sku.name,
      price: calcPrice(sku).effective_price_exclude_tax,
    }))

    // 合計価格を計算
    const totalValue = items.reduce((sum, item) => sum + item.price, 0)

    gtag("event", "add_to_cart", {
      currency: "JPY",
      value: totalValue,
      items,
      path: route.fullPath,
    })
  }

  const trackBannerClick = (parts: keyof typeof BannerType, uniqKey: string, index: number, alt: string): void => {
    if (!isProd) return
    const partsName = BannerType[parts]

    const bannerId = alt || `${parts}-${uniqKey}`
    gtag("event", "banner_click", {
      banner_id: bannerId,
      banner_index: index,
      parts_name: partsName,
      timestamp: dayjs().valueOf(),
      path: route.fullPath,
    })
  }

  const trackRecommendProductClick = (productId: string, recommendId: string, displayType: string) => {
    if (!isProd) return
    gtag("event", "click_recommend_item", {
      recommend_id: recommendId,
      display_type: displayType,
      product_id: productId,
    })
  }

  const trackRecommendProductView = (productId: string, recommendId: string, displayType: string) => {
    if (!isProd) return
    gtag("event", "inview_recommend_item", {
      recommend_id: recommendId,
      display_type: displayType,
      product_id: productId,
    })
  }

  const trackSizeRecommendClick = ({
    product_id,
    gender,
    height,
    weight,
    foot_size,
    recommend_size,
  }: SizeRecommendEventArg) => {
    if (!isProd) return
    gtag("event", "click_size_recommend", {
      product_id,
      gender,
      height,
      weight,
      foot_size,
      recommend_size,
    })
  }

  const trackCategoryClick = (category: string) => {
    if (!isProd) return
    gtag("event", "click_category", {
      category,
      path: route.fullPath,
    })
  }

  const trackProductListItemClick = (product_id: string) => {
    if (!isProd) return
    gtag("event", "click_product_list_item", {
      product_id,
      path: route.fullPath,
    })
  }

  return {
    trackAddToCart,
    trackBannerClick,
    trackRecommendProductClick,
    trackRecommendProductView,
    trackSizeRecommendClick,
    trackCategoryClick,
    trackProductListItemClick,
  }
}
