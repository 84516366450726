export const mapAsync = <T, R>(array: T[], cb: (value: T, index: number) => Promise<R>): Promise<R[]> => {
  return Promise.all(array.map(cb))
}

export const forEachAsync = async <T>(array: T[], cb: (value: T, index: number) => Promise<void>): Promise<void> => {
  await mapAsync(array, cb)
}

type Range = {
  (length: number): Generator<number>
  (start: number, stop: number, step?: number): Generator<number>
}

/**
 * `rArray`のジェネレータ版
 * ```
 * for (const i of range(n)) {...}
 * ```
 */
export const range: Range = function* (start: number, stop?: number, step?: number) {
  if (stop == null) {
    ;[start, stop] = [0, start]
    while (start < stop) yield start++
    return
  }
  step = Math.abs(step || 1)
  if (start < stop) {
    while (start <= stop) {
      yield start
      start += step
    }
  } else {
    while (start >= stop) {
      yield start
      start -= step
    }
  }
}

type Range_ = {
  (length: number): number[]
  (start: number, stop: number, step?: number): number[]
}

/**
 * `range`を配列に展開して返す
 * ```
 * > Array(5)
 * [ <5 empty items> ]
 * > rArray(5)
 * [0, 1, 2, 3, 4]
 * > rArray(1, 5)
 * [1, 2, 3, 4, 5]
 * > rArray(10, 0, 2)
 * [10, 8, 6, 4, 2, 0]
 * ```
 * 参考: https://docs.python.org/ja/3/library/stdtypes.html#typesseq-range
 */
export const rArray: Range_ = (start: number, stop?: number, step?: number) => {
  return [...(stop == null ? range(start) : range(start, stop, step))]
}
