// 在庫切れ判定(通常販売のみ)
export const isSkuOutOfStockOnlyNormal = (sku: {
  is_reservation: boolean
  stock_quantity: number
  stock_quantity_limit: number
  egift_stock_quantity_limit: number
  stock_reservation_quantity: number
}) => {
  const stock_quantity = sku.stock_quantity || 0
  const stock_quantity_limit = sku.stock_quantity_limit || 0
  const egift_stock_quantity = sku.egift_stock_quantity_limit || 0
  const stock_reservation_quantity = sku.stock_reservation_quantity || 0

  if (sku.is_reservation) return false
  return (
    stock_quantity === 0 || stock_quantity < stock_quantity_limit + egift_stock_quantity + stock_reservation_quantity
  )
}

// 在庫切れ(予約販売のみ)
export const isSkuOutOfStockOnlyReservation = (sku: {
  is_reservation: boolean
  net_stock_quantity: number
  net_stock_quantity_limit: number
}) => {
  const net_stock_quantity = sku.net_stock_quantity || 0
  const net_stock_quantity_limit = sku.net_stock_quantity_limit || 0

  return sku.is_reservation && net_stock_quantity < net_stock_quantity_limit
}

export const isSkuOutOfStock = (sku: {
  stock_quantity: number
  stock_quantity_limit: number
  egift_stock_quantity_limit: number
  stock_reservation_quantity: number
  net_stock_quantity: number
  net_stock_quantity_limit: number
  is_reservation: boolean
}) => {
  return isSkuOutOfStockOnlyNormal(sku) || isSkuOutOfStockOnlyReservation(sku)
}
