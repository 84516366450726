<template>
  <div v-if="isSpColumn && !minScreenLg" :class="$style.item_columns">
    <PagePartsProductListItem
      v-for="product in products"
      :key="`sp-${product.document_id}`"
      :class="$style.column"
      :product="product"
      :is-horizontal="isHorizontal"
      :is-vertical="isVertical"
      :is-show-event="isShowEvent"
      :button-variant="buttonVariant"
      @open-fav-modal="openFavModal($event, product)"
      @click="emit('click', product)"
      @show="emit('show', product)"
    />
  </div>
  <PagePartsProductListCarousel v-else :item-length="products.length" :slides-to-scroll="slidesToScroll">
    <template #items>
      <PagePartsProductListItem
        v-for="product in products"
        :key="`md-${product.document_id}`"
        :class="$style.slide"
        :product="product"
        :is-horizontal="isHorizontal"
        :is-vertical="isVertical"
        :is-show-event="isShowEvent"
        :button-variant="buttonVariant"
        @open-fav-modal="openFavModal($event, product)"
        @click="emit('click', product)"
        @show="emit('show', product)"
      />
    </template>
    <template #spItems>
      <PagePartsProductListItem
        v-for="product in products"
        :key="`sp-${product.document_id}`"
        :class="$style.slide"
        :product="product"
        :is-horizontal="isHorizontal"
        :is-vertical="isVertical"
        :is-show-event="isShowEvent"
        :button-variant="buttonVariant"
        @open-fav-modal="openFavModal($event, product)"
        @click="emit('click', product)"
        @show="emit('show', product)"
      />
    </template>
  </PagePartsProductListCarousel>
  <MoleculesConsumerModal v-model="isOpenFavModal">
    <ProductSelectFav
      :product="favModalProduct"
      :favorite-list="favoriteListRef"
      :is-processing="isProcessing"
      :is-show-event="isShowEvent"
      @on-favorite="onFavoriteByProductList"
      @lift="() => (isOpenFavModal = false)"
    />
  </MoleculesConsumerModal>
</template>

<script setup lang="ts">
import type { VariantType } from "@tential/consumer-component/type"
import type { PagePartsProductType } from "~/types/product"
import { useFavorite } from "~/composables/useFavorite"

const { openFavModal, onFavoriteByProductList, isOpenFavModal, favModalProduct, favoriteListRef, isProcessing } =
  useFavorite()

const { width: windowWidth } = useWindowSize()
// @mixin lg と同じ値に設定
const minScreenLg = computed(() => windowWidth.value >= 1024)

withDefaults(
  defineProps<{
    products: PagePartsProductType[]
    isHorizontal?: boolean
    isVertical?: boolean
    isSpColumn?: boolean
    slidesToScroll?: number
    /** 要素が表示されたイベントを送信するかどうか */
    isShowEvent?: boolean
    /** ボタンスタイル */
    buttonVariant?: VariantType
  }>(),
  {
    isHorizontal: false,
    isVertical: false,
    isSpColumn: false,
    slidesToScroll: 3,
    isShowEvent: false,
    buttonVariant: undefined,
  },
)

const emit = defineEmits<{
  (e: "click", product: PagePartsProductType): void
  (e: "show", product: PagePartsProductType): void
}>()
</script>

<style scoped module lang="scss">
.slide {
  cursor: pointer;
  flex: 0 0 70%;
  @include md {
    flex: 0 0 25%;
  }
}
.item_columns {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  .column {
    flex: auto;
  }
}
</style>
