import type { Sku } from "@tential/ec-gql-schema/models/sku"

/** TikTok Pixel カートに追加イベント */
export const trackTtqAddCart = (skus: Sku[]): void => {
  if (window.ttq) {
    const contents = skus.map((sku) => ({
      content_id: sku.document_id,
      content_name: sku.name,
    }))

    window.ttq.track("AddToCart", {
      contents,
      content_type: "product",
    })
  }
}
