import { errorMessage } from "@tential/ec-gql-schema/utils/functions/error"
import { useStore } from "~/stores"
import { useConfirmDialog } from "~/composables/useConfirmDialog"
import type { SelectFavProps_Product } from "~/types/product"
import { useOwnFavoriteCreateMutation, useOwnFavoriteRemoveMutation } from "~/gql/urql.generated"
import { useToast } from "~/state"
import type { ApiDataFavorite } from "~/types/server/api/data/favorite"

export const useFavorite = () => {
  const store = useStore()
  const isSigned = computed(() => store.user.isSigned)
  const router = useRouter()
  const route = useRoute()
  const toast = useToast()
  const { proceed } = useConfirmDialog()
  const { $fetchAuth } = useAuth()
  const isOpenFavModal = ref(false)
  const isProcessing = ref(false)

  const favModalProduct = ref<SelectFavProps_Product | null>(null)
  const favoriteListRef = ref<ApiDataFavorite[]>([])

  const { executeMutation: favoriteMutation } = useOwnFavoriteCreateMutation()
  const { executeMutation: unfavoriteMutation } = useOwnFavoriteRemoveMutation()

  const signInCheck = async (): Promise<boolean> => {
    if (!isSigned.value) {
      if (!(await proceed("商品をお気に入りに登録するには、ログインが必要です。ログインしますか？"))) return false
      const currentPath = route.fullPath
      const redirectPath = `?next=${currentPath}`
      router.push(`/sign_in${redirectPath}`)
      return false
    }
    return true
  }

  const fetchFavoriteByProductId = async (productId: string) => {
    return await $fetchAuth(`/api/data/favorite/product/${productId}`, {
      method: "GET",
    })
  }

  const openFavModal = async (event: Event, product: SelectFavProps_Product): Promise<void> => {
    event.preventDefault()
    const isSignedIn = await signInCheck()
    if (!isSignedIn) return
    if (product) {
      favModalProduct.value = product
      favoriteListRef.value = await fetchFavoriteByProductId(product.document_id)
      isOpenFavModal.value = true
    }
  }

  const onFavoriteByProductList = async (skuId: string, productId: string): Promise<void> => {
    isProcessing.value = true
    try {
      const existsFav = favoriteListRef.value.some((fav) => fav.sku_id === skuId)

      const res = existsFav
        ? await unfavoriteMutation({ skuId })
        : await favoriteMutation({
            skuId,
            productId,
          })

      if (res.error) throw new Error(existsFav ? "お気に入り削除に失敗しました" : "お気に入り登録に失敗しました")
      favoriteListRef.value = await fetchFavoriteByProductId(productId)
    } catch (e) {
      const errMes = errorMessage(e)
      toast?.showErrorToasted(errMes)
    } finally {
      isProcessing.value = false
    }
  }

  const onFavoriteByCart = async (alreadyFavorite: boolean, skuId: string, productId: string): Promise<boolean> => {
    if (isProcessing.value) return false
    try {
      isProcessing.value = true
      const res = alreadyFavorite
        ? await unfavoriteMutation({ skuId })
        : await favoriteMutation({
            skuId,
            productId,
          })
      if (res.error) throw new Error(alreadyFavorite ? "お気に入り削除に失敗しました" : "お気に入り登録に失敗しました")
      favoriteListRef.value = await fetchFavoriteByProductId(productId)

      return true
    } catch (e) {
      const errMes = errorMessage(e)
      toast?.showErrorToasted(errMes)
      return false
    } finally {
      isProcessing.value = false
    }
  }

  const fetchFavoriteListByCart = async (productId: string) => {
    favoriteListRef.value = await fetchFavoriteByProductId(productId)
  }

  return {
    openFavModal,
    signInCheck,
    isOpenFavModal,
    favModalProduct,
    favoriteListRef,
    onFavoriteByProductList,
    isProcessing,
    onFavoriteByCart,
    fetchFavoriteListByCart,
    isSigned,
  }
}
