/**
 * [local,dev用] 2buy35000以上クーポンのID
 */
export const DEV_RECOMMEND_COUPON_ID = "64f550ecc45686fb8ceebda1"
/**
 * [本番用] 2buy35000以上クーポンのID
 */
export const PRODUCTION_RECOMMEND_COUPON_ID = "64f550ecc45686fb8ceebda1"

export const COUPON_VALIDATE_MESSAGE = {
  EXPIRED: "クーポンの利用期間外です",
  NOT_MIN_PRICE: "クーポンの利用最低金額を満たしていません。",
  MAX_COUNT: "クーポンの利用回数上限を超えています。",
  INVALID_PATTERN: "クーポンの利用に必要な商品が入っていません",
  IS_EXCHANGE_COUPON: "クーポンの利用条件を満たしていません。対象商品を1つカートに入れる事でご利用いただけます",
  ALL_NOT_TARGET: "すべての商品が全クーポン適用対象外のため、クーポンが利用できません",
  NOT_FOUND: "クーポンが見つかりません",
} as const

export const CHECK_PURCHASE_COUPON_ERROR_MESSAGE = {
  CANCELLATION: "クーポンの適用が解除されました。金額を再度お確かめの上お試しください",
} as const
