<template>
  <div :class="[$style.recommend_products_area, $style.no_padding]">
    <PagePartsProductListItemCarousel
      :products="recommendProducts"
      :is-horizontal="isHorizontal"
      :is-vertical="isVertical"
      :is-sp-column="isSpColumn"
      :is-show-event="true"
      :slides-to-scroll="slidesToScroll"
      @click="onProductClick"
      @show="onProductShow"
    />
  </div>
</template>

<script setup lang="ts">
import { PagePartsProductType } from "~/types/product"

type Props = {
  recommendProducts: PagePartsProductType[]
  isHorizontal?: boolean
  isVertical?: boolean
  isSpColumn?: boolean
  slidesToScroll?: number
  gaDisplayType?: string
}

const props = withDefaults(defineProps<Props>(), {
  recommendProducts: () => [],
  isHorizontal: false,
  isVertical: false,
  isSpColumn: false,
  slidesToScroll: 3,
  gaDisplayType: "",
})

const { trackRecommendProductClick, trackRecommendProductView } = useGtagEvent()

const onProductClick = (product: PagePartsProductType) => {
  if (props.gaDisplayType && product.recommend_id) {
    trackRecommendProductClick(product.document_id, product.recommend_id, props.gaDisplayType)
  }
}

const onProductShow = (product: PagePartsProductType) => {
  if (props.gaDisplayType && product.recommend_id) {
    trackRecommendProductView(product.document_id, product.recommend_id, props.gaDisplayType)
  }
}
</script>

<style module scoped lang="scss">
.recommend_products_area {
  @include container-lg;
  margin-top: 1.75rem;
  @include md {
    margin-top: 2.5rem;
  }

  &.no_padding {
    padding: 0;
  }
}
</style>
