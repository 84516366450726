<template>
  <div :class="$style.tax_in_price">
    <p :class="[$style.price, { [$style.discount]: taxInDiscountedPrice }]" :style="{ color: priceColor }">
      <template v-if="!taxInDiscountedPrice && !taxInPrice">無料</template>
      <template v-else>
        ¥{{ taxInDiscountedPrice || taxInPrice }}
        <span :class="$style.label">参考税込</span>
      </template>
    </p>
    <p v-if="taxInDiscountedPrice && !props.sku?.is_discounted_price_only" :class="$style.compare">
      ¥{{ taxInPrice }}
      <span :class="$style.label">参考税込</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { calcPrice } from "@tential/ec-gql-schema/utils/functions/price"
// propsの定義
interface Props {
  sku: {
    price: number
    discounted_price?: number
    consumption_tax_percentage: number
    is_discounted_price_only?: boolean
  } | null
  priceColor?: string
}

const props = defineProps<Props>()

const taxInPrice = computed(() => {
  return props.sku?.price ? calcPrice(props.sku).original_reference_price_comma : ""
})

const taxInDiscountedPrice = computed(() => {
  return props.sku?.discounted_price ? calcPrice(props.sku).effective_reference_price_comma : ""
})
</script>

<style module lang="scss">
.tax_in_price {
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
  .price {
    @include main-font;
    font-size: $font-size-20;
    .label {
      font-size: $font-size-10;
    }
    &.discount {
      color: $danger;
    }
  }

  .compare {
    @include main-font;
    display: flex;
    position: relative;
    align-items: flex-end;
    font-size: $font-size-15;
    .label {
      font-size: $font-size-9;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $black;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
}
</style>
