/** [本番用] ニュース欄掲載用のグループID */
export const PRODUCTION_NEWS_GROUP_ID = "65684ba53bb3434702e848f8" as const
/** [local,dev用] ニュース欄掲載用のグループID */
export const DEV_NEWS_GROUP_ID = "6541d54fa3858eec8ee63c9a" as const

/** 人気の商品カルーセル表示用の商品ID */
export const POPULAR_PRODUCT_IDS = [
  "64f7df0b3c023b563b812d17", // 【RENEWAL】BAKUNE 上下セット
  "64faa9a22b0f5ecb32ed175f", // 【RENEWAL】BAKUNE Ladies 上下セット（長袖・ジョガーパンツ）
  "66d6b386bfcf79b7ab52f801", // MIGARU Tech Sweat Pullover Hoodie 上下セット（プルオーバーフーディー・ジョガーパンツ）
  "66d55b33bfcf79b7ab4df83e", // 【RENEWAL】Recovery Sandal Warm
  "66f64b241ce0af8ea4f6fa31", // BAKUNE Comforter Warm 冬用掛け布団
]
/** BAKUNEカルーセル表示用の商品ID */
export const RECOVERY_WEAR_PRODUCT_IDS = [
  "64f7df0b3c023b563b812d17", // 【RENEWAL】BAKUNE 上下セット
  "64faa9a22b0f5ecb32ed175f", // 【RENEWAL】BAKUNE Ladies 上下セット（長袖・ジョガーパンツ）
  "66fb786b03caac222dc56921", // BAKUNE Pajamas Satin 上下セット（長袖シャツ・ロングパンツ）
  "66fb802703caac222dc63547", // BAKUNE Warm 上下セット（長袖シャツ・ロングパンツ）
  "64db13995618956d2114a4c9", // 【RENEWAL】BAKUNE Dry 上下セット（長袖・レギュラーパンツ）
  // "66fb720103caac222dc41bdd", // BAKUNE Soft Knit 上下セット（長袖シャツ・ロングパンツ） 在庫切れで一旦非表示
  // "66fb658a03caac222dc34220", // BAKUNE Velour 上下セット（長袖・ロングパンツ） 在庫切れで一旦非表示
]
/** MIGARUカルーセル表示用の商品ID */
export const COMFORT_TECH_WEAR_PRODUCT_IDS = [
  "66d6b386bfcf79b7ab52f801", // MIGARU Tech Sweat Pullover Hoodie 上下セット（プルオーバーフーディー・ジョガーパンツ）
  "66d6b468bfcf79b7ab533846", // MIGARU Tech Sweat Collarless Jacket 上下セット（ノーカラージャケット・ロングパンツ）
  "66d6bbdfbfcf79b7ab537cf9", // MIGARU Tech Sweat Collarless Jacket 上下セット（ノーカラージャケット・レディースロングパンツ）
  "66d6c6e6bfcf79b7ab53ac77", // MIGARU Tech Sweat Coach Jacket 上下セット（コーチジャケット・ロングパンツ）
  "66d6c885bfcf79b7ab53e4e7", // MIGARU Tech Sweat Ladies Tunic チュニック
]
/** 寝具カルーセル表示用の商品ID */
export const BEDDING_PRODUCT_IDS = [
  "66f64b241ce0af8ea4f6fa31", // BAKUNE Comforter Warm 冬用掛け布団
  "66fa427113b2b198b6935b5a", // BAKUNE Comforter Extra Warm 真冬用掛け布団
  "66fa3e9a13b2b198b6933318", // BAKUNE Bed Pad Warm 冬用敷きパッド
  "66960f7f3f513e5f6128ae8b", // BAKUNE Comforter All Seasons 通年用掛け布団
  "66fa520313b2b198b6945ced", // 真冬用寝具2点セット 真冬用掛け布団・冬用敷きパッド
  // "66988085dc529db4f61511d2", // BAKUNE Bed Pad All Seasons 通年用敷きパッド 在庫切れで一旦非表示
]
/** サンダルカルーセル表示用の商品ID */
export const SANDAL_PRODUCT_IDS = [
  "66d55b33bfcf79b7ab4df83e", // 【RENEWAL】Recovery Sandal Warm
  "646da364a68c482af0f1b2b3", // Recovery Sandal Slide
  "646d68ac7a3fbe961f40794f", // Recovery Sandal Flip flop
  "62b99ee08d6899001293ea7b", // Conditioning Sandal Flip flop
  "647e9ddeafc9bf4103be2608", // Conditioning Sandal Slide
  "663b0a843e870be40fc2c508", // Recovery Sandal Strap
]
