import { defineStore } from "pinia"
export const useConfirmDialogStore = defineStore("confirmDialog", () => {
  const isShow = ref(false)
  const isAgree = ref(false)
  const message = ref("")
  const buttonText = ref({ agree: "OK", disagree: "キャンセル" })
  const show = (_message: string, _buttonText?: { agree?: string; disagree?: string }) => {
    isShow.value = true
    isAgree.value = false
    message.value = _message
    // デフォルト値を使用
    buttonText.value = {
      agree: _buttonText?.agree || "OK",
      disagree: _buttonText?.disagree || "キャンセル",
    }
  }
  const hide = () => {
    isAgree.value = false
    isShow.value = false
  }

  const agree = () => {
    isAgree.value = true
    isShow.value = false
  }

  return { isShow, isAgree, message, buttonText, show, hide, agree }
})
