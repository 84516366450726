import { ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import { calcPrice } from "@tential/ec-gql-schema/utils/functions/price"
import { PagePartsFragmentFragment } from "~/types/pageParts"
import { loadScript, removeScript } from "~/utils/metaScript"
import { KarteCustomEventType } from "~/types/karte"
import { getSiteProductPageUrl } from "~/utils/functions/product"
import { ProductFragmentFragment } from "~/types/type.generated"

export const karteCustomEventPush = (
  _customEventType: KarteCustomEventType,
  params?: Record<string, unknown>,
): void => {
  if (window.krt) window.krt("send", _customEventType, params)
}

export const appendKarteScripts = (fullPath: string): void => {
  const config = useRuntimeConfig()
  if (!["production"].includes(config.public.APP_ENV ? config.public.APP_ENV : "") || fullPath.includes("journals"))
    return
  loadScript("https://cdn-edge.karte.io/934a7c3ffaaa92115dd3c1074bb8c824/edge.js", "edge-script")
  loadScript("https://cdn-blocks.karte.io/934a7c3ffaaa92115dd3c1074bb8c824/builder.js", "builder-script")

  const inlineScript = document.createElement("script")
  inlineScript.textContent = `
    !function(n){var a=window[n]=function(){var n=[].slice.call(arguments);return a.x?a.x.apply(0,n):a.q.push(n)};a.q=[],a.i=Date.now()}("krt")
  `
  inlineScript.id = "inline-script"
  if (!document.getElementById("inline-script")) {
    document.head.appendChild(inlineScript)
  }
}

export const removeKarteScripts = (fullPath: string) => {
  const config = useRuntimeConfig()
  if (!["production"].includes(config.public.APP_ENV ? config.public.APP_ENV : "") || !fullPath.includes("journals"))
    return
  removeScript("edge-script")
  removeScript("builder-script")
  removeScript("inline-script")
}

type KarteCartEventType = {
  addCart: boolean
  productId: string
  cartLine: ProductAndSku[]
  cartLength: number
  totalPrice: number
}

export const karteCartEvent = (params: KarteCartEventType): void => {
  const config = useRuntimeConfig()
  if (config.public.APP_ENV === "production") {
    const { addCart, productId, cartLine, cartLength, totalPrice } = params
    const karteCartEventParams: {
      status: boolean
      total_price: number
      quantity: number
      add_item_id: string
      delete_item_id: string
      items: {
        item_id: string
        item_name: string
        item_slug: string
        item_price: number
        item_url: string
        item_image_url: string
        quantity: number
        l_category: string
        m_category?: string
      }[]
    } = {
      status: false,
      total_price: 0,
      quantity: 0,
      items: [],
      add_item_id: "",
      delete_item_id: "",
    }
    const mapProductsToKarteItems = cartLine.map((cartItem) => {
      const productPageUrl = getSiteProductPageUrl(cartItem.product)
      return {
        item_id: cartItem.product.document_id,
        item_name: cartItem.product.name,
        item_price: calcPrice(cartItem.sku).effective_price_exclude_tax,
        item_slug: cartItem.product.slug,
        item_url: productPageUrl,
        item_image_url: cartItem.sku.main_image,
        quantity: cartItem.quantity,
        l_category:
          cartItem.product.product_categories && cartItem.product.product_categories.length > 0
            ? cartItem.product.product_categories[0].name
            : "",
        m_category:
          cartItem.product.product_categories &&
          cartItem.product.product_categories.length > 0 &&
          cartItem.product.product_categories &&
          cartItem.product.product_categories[0].parent_category?.name
            ? cartItem.product.product_categories[0]?.parent_category?.name
            : "",
      }
    })
    if (mapProductsToKarteItems.length > 0) {
      karteCartEventParams.status = true
      karteCartEventParams.items = mapProductsToKarteItems
      karteCartEventParams.quantity = cartLength
      karteCartEventParams.total_price = totalPrice
    }
    if (addCart) {
      karteCartEventParams.add_item_id = productId
    } else {
      karteCartEventParams.delete_item_id = productId
    }
    karteCustomEventPush("cart", karteCartEventParams)
  }
}

/**
 * KARTE, GAに送信する商品情報を生成する
 */
export const createProduct = (
  product: PagePartsFragmentFragment["product"],
  productCategories: ProductFragmentFragment["product_categories"],
  config: {
    priceKey: string
    category3Key: string
    category2Key: string
    categoryKey: string
  },
) => {
  return {
    item_id: product?.document_id || "",
    item_name: product?.name || "",
    [config.priceKey]: product?.skus?.length ? product.skus[0].price : 0,
    [config.category3Key]: productCategories[0]?.parent_category?.name || "",
    [config.category2Key]: productCategories[0]?.name || "",
    [config.categoryKey]:
      productCategories.find(
        (category) => category.slug === "foot-wear" || category.slug === "work-support" || category.slug === "sleep",
      )?.name || "",
  }
}
