<template>
  <div :class="$style.loading_wrap">
    <div :class="$style.loading">
      <img
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA0NCA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMxLjk5NzQgMEgwVjEyLjMxNThIMzEuOTk3NFYwWiIgZmlsbD0iI0U4Q0RBOSIvPgo8cGF0aCBkPSJNMzEuMjU3OSAwQzI5LjQ0NzEgMCAyOC42MDMzIDEuMDQyNCAyOC42MDMzIDIuNzcwMDhWMTIuMzI1NEg0NFYwSDMxLjI0ODQiIGZpbGw9IiM1NjdFQjQiLz4KPHBhdGggZD0iTTMwLjQzMzEgMEMyOS43NTA1IDAgMjkuMTkxMSAwLjI5OTIwNyAyOC42NjAyIDAuNzE0MjM2TDE1LjgzMjggMTEuOTU4NkMxNS41NjczIDEyLjE5MDMgMTUuNDE1NyAxMi41MjgxIDE1LjQxNTcgMTIuODg1MlY1NkgyOC42MTI4VjIuNzcwMDhDMjguNjUwNyAxLjEzODkyIDI5LjY2NTIgMC4zODYwNzQgMzAuMTQ4NyAwLjE4MzM4NUMzMC41Mjc5IDAuMDI4OTU1NSAzMS4wMzk5IDAgMzEuMjY3NCAwQzMxLjA3NzggMCAzMC43MTc1IDAgMzAuNDMzMSAwWiIgZmlsbD0iIzI4NEI3RCIvPgo8L3N2Zz4K"
        :class="$style.loading_img"
        alt="Loading"
      />
      <slot />
    </div>
  </div>
</template>

<style module scoped lang="scss">
.loading_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .loading {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .loading_img {
      width: 3rem;
      animation: flip ease-in-out infinite 3s 0.3s;

      @include md {
        width: 6rem;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0);
        }
        20% {
          transform: rotateY(180deg);
        }
        40% {
          transform: rotateY(0);
        }
      }
    }
  }
}
</style>
