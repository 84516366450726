<template>
  <MoleculesConsumerModal
    v-model="modalVisible"
    :class="$style.quick_view_modal"
    snap-to-edge="bottom"
    :min-width="minScreenLg ? '500px' : '100%'"
    @update:model-value="closeQuickView"
  >
    <div v-show="!isLoaded">
      <AtomsConsumerSkeletonLoading />
    </div>
    <div v-if="isLoaded && product" :class="$style.container">
      <ClientOnly>
        <PagePartsCartPartsSelectColor
          v-if="quickViewSelectedSku && skusHasColor"
          :selected-sku="quickViewSelectedSku"
          :selected-size="selectedSize"
          :skus="product.skus"
          :click-event="changeSelectedSku"
          :selected-color-label="selectedSkuColorLabel"
          color-type="image"
        />
      </ClientOnly>

      <div :class="$style.size_container">
        <div :class="$style.size">
          <div :class="$style.left">
            <p>サイズ</p>
            <p>{{ selectedSkuSizeText }}</p>
          </div>
          <AtomsConsumerLink
            v-if="product.size_chart && (product.size_chart.pc_img_url || product.size_chart.sp_img_url)"
            :class="$style.link"
            @click="sizeChartVisible = true"
            >サイズチャートを見る</AtomsConsumerLink
          >
        </div>
        <PagePartsCartPartsSelectSize
          v-if="quickViewSelectedSku"
          :selected-sku="quickViewSelectedSku"
          :selected-size="selectedSize"
          :skus="product.skus"
          :click-event="changeSelectedSku"
          :is-error="!!selectedSizeErrorMessage"
        />
        <p v-if="!!selectedSizeErrorMessage" :class="[$style.error_message]">
          {{ selectedSizeErrorMessage }}
        </p>
      </div>

      <div :class="$style.bottom_button">
        <AtomsConsumerLink :url="getProductLink(product)" :class="$style.button_link">
          <AtomsConsumerButton variant="secondary" block rounded @click="closeQuickView"
            >詳細を見る</AtomsConsumerButton
          >
        </AtomsConsumerLink>
        <CartAddCartButton
          v-if="quickViewSelectedSku"
          :product="product"
          :sku="quickViewSelectedSku"
          :selected-size="selectedSize"
          :is-cart-not-addable="isBeforeReleaseDate(product)"
          :class="[$style.add_to_cart, `gtm_add_cart_btn_${product.slug}`]"
          rounded
          @select-size-validation="selectSizeValidation"
          @after-add-cart="clickedAddCartButton"
        />
      </div>
    </div>
    <p v-else-if="isLoaded">商品を読み込めませんでした。しばらくしてから再度お試しください。</p>
    <MoleculesConsumerModal v-model="sizeChartVisible" :enable-browser-back-close="false">
      <div :class="$style.image_modal">
        <AtomsConsumerImage
          v-if="minScreenLg && product?.size_chart?.pc_img_url"
          :src="product.size_chart.pc_img_url"
        />
        <AtomsConsumerImage
          v-if="!minScreenLg && product?.size_chart?.sp_img_url"
          :src="product.size_chart.sp_img_url"
        />
      </div>
    </MoleculesConsumerModal>

    <CommonModalRestockNotice
      v-if="isRestockNoticeSku && quickViewSelectedSku && product"
      :sku="quickViewSelectedSku"
      :product="product"
      @close="closeQuickView"
    />
  </MoleculesConsumerModal>
</template>

<script setup lang="ts">
import type { EventHandler } from "~/types/events"
import { getSiteProductPageUrl, isBeforeReleaseDate } from "~/utils/functions/product"
import type {
  PublicProductFindOneQuery,
  PublicProductFindOneQueryVariables,
  SkuFragmentFragment,
} from "~/types/type.generated"
import type { PagePartsProductType } from "~/types/product"
import { defineRestockNoticeModal } from "~/state"
import { usePublicProductFindOneQuery } from "~/gql/urql.generated"
import { useCart } from "~/composables/useCart"

defineRestockNoticeModal()
const { cartState } = useCart()

const isLoaded = ref(false)
const modalVisible = ref(false)
const { width: windowWidth } = useWindowSize()
// @mixin lg と同じ値に設定
const minScreenLg = computed(() => windowWidth.value >= 1024)
const sizeChartVisible = ref(false)
const product = ref<PublicProductFindOneQuery["publicProductFindOne"]>()
const selectedSize = ref<string>("")
const selectedSizeErrorMessage = ref<string>("")
const quickViewSelectedSku = ref<SkuFragmentFragment | undefined>()

const isRestockNoticeSku = computed(() => {
  return quickViewSelectedSku.value?.is_restock_notice && isSkuOutOfStock(quickViewSelectedSku.value)
})

const productVariables = reactive<PublicProductFindOneQueryVariables>({
  filter: {
    document_id: "",
    is_status: true,
  },
})
const { executeQuery: executeQueryProductOne } = await usePublicProductFindOneQuery({
  requestPolicy: "network-only",
  variables: productVariables,
  pause: true,
})

const getProductLink = (product: PagePartsProductType): string => {
  return getSiteProductPageUrl(product)
}

const selectedSkuSizeText = computed(() => {
  return selectedSize.value ? selectedSize.value : "未選択"
})

const changeSelectedSku: EventHandler = (_sku: SkuFragmentFragment, isColor: boolean) => {
  quickViewSelectedSku.value = _sku
  if (isColor) {
    selectedSize.value = ""
    return
  }
  selectedSize.value = _sku.size
  if (selectedSizeErrorMessage.value) selectedSizeErrorMessage.value = ""
}

const selectedSkuColorLabel = computed(() => {
  if (quickViewSelectedSku.value?.color_label) return quickViewSelectedSku.value.color_label
  return "カラー"
})

const skusHasColor = computed(() => {
  return product.value?.skus?.some((sku) => typeof sku.color === "string" && sku.color !== "")
})

const closeQuickView = () => {
  modalVisible.value = false
  cartState.quickViewProductId = ""
}

const selectSizeValidation = (): void => {
  if (!selectedSize.value) {
    selectedSizeErrorMessage.value = "サイズを選択してください"
    return
  }
  selectedSizeErrorMessage.value = ""
}

const clickedAddCartButton = () => {
  if (selectedSizeErrorMessage.value) return
  // 再入荷通知の場合、カート追加ボタン押下時にモーダルを閉じない
  if (isRestockNoticeSku.value) return
  closeQuickView()
}

watch(
  () => cartState.quickViewProductId,
  async (productId: string) => {
    if (productId) {
      isLoaded.value = false
      modalVisible.value = true
      productVariables.filter = {
        document_id: productId,
        is_status: true,
      }
      if (executeQueryProductOne().isPaused) await executeQueryProductOne().resume()
      const { data } = await executeQueryProductOne()
      product.value = data?.value?.publicProductFindOne

      const skus = product.value?.skus ?? []
      const inStockSkus = skus.filter((sku) => !isSkuOutOfStock(sku))
      const selectedSku = inStockSkus[0] ?? skus[0] ?? null

      quickViewSelectedSku.value = selectedSku
      selectedSize.value = inStockSkus.length === 1 ? selectedSku?.size ?? "" : ""
      isLoaded.value = true
    }
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped module>
.quick_view_modal {
  .container {
    width: 100%;
    height: auto;
    max-height: 85vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0.125rem;
    margin-top: 1rem;
    gap: 0.5rem;
    position: relative;
    .size_container {
      margin-bottom: 1.5rem;
      .size {
        display: flex;
        justify-content: space-between;
        gap: 0.75rem;
        .left {
          display: flex;
          gap: 0.25rem;
          p {
            font-size: $font-size-12;
            font-weight: 400;

            @include md {
              font-size: $font-size-14;
            }
          }
        }
        .link {
          font-size: $font-size-12;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .error_message {
        margin-top: 0.5rem;
        font-size: 12px;
        color: $danger;
        font-weight: bold;
      }
    }
    .description {
      font-size: $font-size-10;
    }
  }
  .bottom_button {
    position: sticky;
    z-index: 10;
    bottom: 0.5rem;
    width: 100%;
    display: flex;
    gap: 0.5rem;
    .button_link,
    .add_to_cart {
      width: 100%;
    }
  }
}
.image_modal {
  @include lg {
    max-width: $max-width-md;
  }
  img {
    min-height: 300px;
    min-width: 300px;
    width: 100%;
    object-fit: contain;
  }
}
</style>
