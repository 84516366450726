import { GiftPattern } from "~/types/server/giftPattern"
import { SkuFragmentFragment } from "~/types/type.generated"
import { getIndividualCards, getIndividualGiftWrappings, getIndividualMessages } from "~/utils/functions/gift"

const FIVE_MINUTES = 5 * 60 * 1000

export const useGift = () => {
  const giftPatterns = useState<{ gifts: GiftPattern[]; lastFetchedTime: number }>("giftPatterns", () => {
    return { gifts: [], lastFetchedTime: 0 }
  })

  const fetchGiftPattern = async (isForceFetch = false) => {
    // 取得したタイミングを記録し5分以内(在庫連携インターバル)の場合は再取得しない
    if (!isForceFetch && giftPatterns.value.lastFetchedTime > Date.now() - FIVE_MINUTES) {
      return
    }
    const gifts = await $fetch<GiftPattern[]>("/api/gift_pattern")
    giftPatterns.value.lastFetchedTime = Date.now()
    giftPatterns.value.gifts = gifts
  }

  const applicableGiftPatterns = (product_id: string) => {
    return (
      giftPatterns.value?.gifts.filter((pattern) => {
        return !pattern.ignore_product_ids.includes(product_id) && !pattern.is_only_group_wrapping
      }) || []
    )
  }

  const applicableGiftPatternsForGroupWrapping = (wrappingSkuIds: string[]) => {
    return giftPatterns.value?.gifts.filter((pattern) => wrappingSkuIds.includes(pattern.document_id)) || []
  }

  const filterIndividualGiftWrappings = (
    product_id: string,
    selectedWrappingId: string | null,
    selectedSku: SkuFragmentFragment | null,
  ) => {
    return getIndividualGiftWrappings(applicableGiftPatterns(product_id), selectedWrappingId, selectedSku)
  }

  const filterIndividualMessages = (product_id: string, selectedWrappingId: string | null) => {
    return getIndividualMessages(applicableGiftPatterns(product_id), selectedWrappingId)
  }

  const filterIndividualCards = (product_id: string, selectedCardId: string | null) => {
    return getIndividualCards(applicableGiftPatterns(product_id), selectedCardId)
  }

  /**
   * まとめてラッピングカート用
   */
  const filterIndividualGiftWrappingsForGroupWrapping = (
    wrappingSkuIds: string[],
    selectedWrappingId: string | null,
    packageSize: number,
  ) => {
    return getIndividualGiftWrappings(
      applicableGiftPatternsForGroupWrapping(wrappingSkuIds),
      selectedWrappingId,
      null,
      packageSize,
    )
  }

  const filterIndividualMessagesForGroupWrapping = (wrappingSkuIds: string[], selectedWrappingId: string | null) => {
    return getIndividualMessages(applicableGiftPatternsForGroupWrapping(wrappingSkuIds), selectedWrappingId)
  }

  const filterIndividualCardsForGroupWrapping = (wrappingSkuIds: string[], selectedCardId: string | null) => {
    return getIndividualCards(applicableGiftPatternsForGroupWrapping(wrappingSkuIds), selectedCardId)
  }

  return {
    fetchGiftPattern,
    applicableGiftPatterns,
    filterIndividualGiftWrappings,
    filterIndividualMessages,
    filterIndividualCards,
    filterIndividualGiftWrappingsForGroupWrapping,
    filterIndividualMessagesForGroupWrapping,
    filterIndividualCardsForGroupWrapping,
  }
}
