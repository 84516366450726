import { CombinedError } from "@urql/core"
import {
  useSiteSocialSignMutation,
  useSiteSocialSignUpMutation,
  useSiteUserSignInMutation,
  useSiteUserSignUpMutation,
} from "~/gql/urql.generated"
import { SiteUserSignInMutation, SiteUserSignUpMutation, EnumUserReceive_Mail } from "~/types/type.generated"
import { useStore } from "~/stores"
import { useToast } from "~/state"
import useCookieState from "~/composables/useCookieState"
import { replaceGraphQLError } from "~/utils/functions/error"

type SignInResult = {
  status: number
  error: CombinedError | undefined
  errMsg?: string
  body?: SiteUserSignInMutation["siteUserSignIn"]
}

type SignUpResult = {
  status: number
  error: CombinedError | undefined
  errMsg?: string
  body?: SiteUserSignUpMutation["siteUserSignUp"]
}

export default () => {
  const { executeMutation: signInMutation } = useSiteUserSignInMutation()
  const { executeMutation: signUpMutation } = useSiteUserSignUpMutation()
  const { executeMutation: innerSocialSignUp } = useSiteSocialSignUpMutation()
  const { executeMutation: innerSiteSocialSign } = useSiteSocialSignMutation()
  const { setSignInCookie, signOutCookie } = useCookieState()

  const store = useStore()
  const toast = useToast()
  const { SITE_SLUG } = useSite()
  const { getAccessToken } = useCookieState()

  // ユーザーの情報をcookieとstoreにsetする関数
  const setUserInfoToCookieAndStore = async (user: {
    access_token: string
    document_id: string
    email: string
    refresh_token: string | undefined
  }) => {
    setSignInCookie(user.access_token, user.refresh_token)

    await store.setUser({
      document_id: user.document_id,
      email: user.email,
      token: user.access_token,
      isSigned: true,
    })
  }

  const signUp = async (email: string, password: string, receiveMail: EnumUserReceive_Mail): Promise<SignUpResult> => {
    const result = await signUpMutation({ site_slug: SITE_SLUG, email, password, receiveMail })

    if (!result.data?.siteUserSignUp?.access_token || !result.data?.siteUserSignUp?.document_id)
      return {
        status: 400,
        error: result?.error,
        errMsg: result?.error?.toString().replace("[GraphQL] ", ""),
        body: undefined,
      }

    await setUserInfoToCookieAndStore({
      access_token: result.data.siteUserSignUp.access_token,
      document_id: result.data.siteUserSignUp.document_id,
      email,
      refresh_token: result.data.siteUserSignUp.refresh_token,
    })

    return {
      status: 200,
      error: undefined,
      errMsg: undefined,
      body: result.data.siteUserSignUp,
    }
  }

  const signIn = async (email: string, password: string): Promise<SignInResult> => {
    const result = await signInMutation({ site_slug: SITE_SLUG, email, password })

    if (!result.data?.siteUserSignIn?.access_token)
      return {
        status: 400,
        error: result?.error,
        errMsg: result?.error?.toString().replace("[GraphQL] ", ""),
        body: undefined,
      }

    await setUserInfoToCookieAndStore({
      access_token: result.data.siteUserSignIn.access_token,
      document_id: result.data.siteUserSignIn.document_id,
      email,
      refresh_token: result.data.siteUserSignIn.refresh_token,
    })

    return {
      status: 200,
      error: undefined,
      errMsg: undefined,
      body: result.data.siteUserSignIn,
    }
  }

  const signOut = () => {
    signOutCookie()
    store.resetOrderState()
    store.resetOut()
  }

  /** ソーシャルプラスでログインする関数 ユーザーが存在しない場合は新規登録用の値を返す */
  const socialSign = async (siteSlug: string, token: string) => {
    const res = await innerSiteSocialSign({ site_slug: siteSlug, token })
    const result = res.data?.siteSocialSign
    if (res.error || !result) {
      const errorMsg = res?.error?.message?.toString().replace("[GraphQL] ", "") || "ログインに失敗しました"
      toast?.showErrorToasted(errorMsg)
      return {
        status: 400,
        error: errorMsg,
        body: undefined,
      }
    }
    if (result.is_login && result.user && result.user.access_token && result.user.email) {
      await setUserInfoToCookieAndStore({
        access_token: result.user.access_token,
        document_id: result.user.document_id || "",
        email: result.user.email,
        refresh_token: result.user.refresh_token,
      })
    }
    return {
      status: 200,
      error: undefined,
      body: result,
    }
  }

  /** ソーシャルプラスで新規登録する関数 */
  const socialSignUp = async (
    siteSlug: string,
    email: string,
    socialId: string,
    password?: string,
    receiveMail?: EnumUserReceive_Mail,
  ) => {
    const res = await innerSocialSignUp({
      email,
      socialId,
      password,
      site_slug: siteSlug,
      receiveMail,
    })
    const resSocialSignUp = res.data?.siteSocialSignUp
    if (res.error || !resSocialSignUp) {
      const errorMsg =
        replaceGraphQLError(res?.error?.message) || "Line連携に失敗しました。パスワードを設定してください"
      return {
        status: 400,
        error: errorMsg,
        body: undefined,
      }
    }

    await setUserInfoToCookieAndStore({
      access_token: resSocialSignUp.access_token || "",
      document_id: resSocialSignUp.document_id,
      email: resSocialSignUp.email,
      refresh_token: resSocialSignUp.refresh_token,
    })

    return {
      status: 200,
      error: undefined,
      body: resSocialSignUp,
    }
  }

  /**
   * 認証用の custom_authorization ヘッダーを付与する $fetch
   * - defineEventHandlerWithAuthUser など認証必要な REST API を実行したい場合に利用する
   */
  const $fetchAuth = $fetch.create({
    ...{
      headers: {
        custom_authorization: `Bearer ${getAccessToken()}`,
      },
    },
  })

  return { signUp, signIn, signOut, socialSign, socialSignUp, setUserInfoToCookieAndStore, $fetchAuth }
}
