<template>
  <div :class="$style.root">
    <div v-if="!isMobile" :class="$style.pc_container">
      <div ref="carousel" :class="$style.carousel">
        <div :class="[$style.container]">
          <slot name="items" />
        </div>
      </div>
      <div
        :class="[
          $style.buttons,
          { [$style.prev_btn_disabled]: prevBtnDisabled },
          { [$style.next_btn_disabled]: nextBtnDisabled },
        ]"
      >
        <AtomsConsumerButton
          :class="[$style.button, $style.prev, { [$style.disabled]: prevBtnDisabled }]"
          :disabled="prevBtnDisabled"
          @click="emblaApi?.scrollPrev()"
        />
        <AtomsConsumerButton
          :class="[$style.button, $style.next, { [$style.disabled]: nextBtnDisabled }]"
          :disabled="nextBtnDisabled"
          @click="emblaApi?.scrollNext()"
        />
      </div>
    </div>
    <div v-else :class="$style.sp_container">
      <PerfectScrollbar :class="$style.perfect_scrollbar">
        <slot name="spItems" />
      </PerfectScrollbar>
    </div>
  </div>
</template>

<script setup lang="ts">
import emblaCarouselVue from "embla-carousel-vue"
import type { EmblaCarouselType } from "embla-carousel"
import { PerfectScrollbar } from "vue3-perfect-scrollbar"
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures"

const props = withDefaults(
  defineProps<{
    itemLength: number
    /** scrollPrev, scrollNext でスクロールするアイテム数 */
    slidesToScroll?: number
  }>(),
  {
    slidesToScroll: 3,
  },
)

const [carousel, emblaApi] = emblaCarouselVue(
  {
    dragFree: true,
    breakpoints: {
      // @mixin md と同じ値に設定
      "(min-width: 768px)": {
        // アイテム数がスクロール数より多い場合は watchDrag を true にする
        watchDrag: props.itemLength > props.slidesToScroll,
        slidesToScroll: props.slidesToScroll,
      },
    },
  },
  [WheelGesturesPlugin()],
)
const prevBtnDisabled = ref<boolean>(true)
const nextBtnDisabled = ref<boolean>(true)

const { isMobile } = useDevice()

const onSelect = (emblaApi: EmblaCarouselType) => {
  prevBtnDisabled.value = !emblaApi.canScrollPrev()
  nextBtnDisabled.value = !emblaApi.canScrollNext()
}
watchEffect(() => {
  if (emblaApi.value) {
    // 初回のインデックスを設定
    onSelect(emblaApi.value)
    emblaApi.value.on("reInit", onSelect)
    emblaApi.value.on("select", onSelect)
  }
})
</script>

<style scoped module lang="scss">
.root {
  width: 100%;
  .pc_container {
    position: relative;
    .carousel {
      overflow: hidden;
      display: flex;
      .container {
        display: flex;
        width: 100%;
        gap: 0.75rem;
        touch-action: pan-y;
      }
    }
    .buttons {
      display: none;
      @include md {
        position: absolute;
        top: 38%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0;
        pointer-events: none;
        &.prev_btn_disabled {
          justify-content: flex-end;
        }
        &.next_btn_disabled {
          justify-content: flex-start;
        }
        .button {
          display: block;
          transform: translateY(-50%);
          width: 56px;
          height: 56px;
          background: url("/images/common/arrow/arrow-secondary.svg") no-repeat center $white;
          border-radius: 50%;
          z-index: 1;
          pointer-events: auto;
          &.disabled {
            display: none;
          }
          &.prev {
            transform: translateY(-38%) scale(-1, 1) translateX(28px);
          }
          &.next {
            transform: translateY(-38%) translateX(28px);
          }
        }
      }
    }
  }
  .sp_container {
    display: flex;
    .perfect_scrollbar {
      display: flex;
      overflow-x: scroll;
      gap: 1rem;
      padding-bottom: 1.625rem;
    }
  }
}
</style>
