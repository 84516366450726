import { apiRes } from "@tential/ec-gql-schema/types/api"
import { CartItem } from "@tential/ec-gql-schema/models/order"
import { ProductAndSku, SkuWithEmbroidery, SkuWithGift, SkuWithGroupWrapping } from "@tential/ec-gql-schema/types/cart"
import { OrderDeliveryMethod } from "@tential/ec-gql-schema/master/order"
import { getNowUnixTime } from "@tential/ec-gql-schema/utils/functions/date"
import { forEachAsync } from "@tential/ec-gql-schema/utils/array"
import { UseManualCoupon, CartPriceState, ValidatedCoupon } from "~/types/cart"
// Path: apps/consumer/composables/useCart.ts
import { useStore } from "~/stores"
import { COOKIE_KEY } from "~/const/cookie"
import { AuthCookie } from "~/types/cookie"
import { karteCartEvent } from "~/utils/functions/karte"
import { trackTtqAddCart } from "~/utils/functions/tiktok"
import { getGroupWrappingUniqId } from "~/utils/functions/gift"

interface CartInfo {
  isClass: boolean
  isShowAddCartModal: boolean
  addCartModalSkuIds: string[]
  scrollPosition: number
  paymentType: string
  useManualCoupon: UseManualCoupon
  quickViewProductId: string
}

/*
 * useCart
 * reactiveな金額とカートリストの表示と表示の補助。（大元はstore.cartList, store.order.amount）
 * 及びカートアイテムの増減によるアクション（金額計算。外部に送信）
 */

export const provideUseCart = () => {
  const store = useStore()
  const config = useRuntimeConfig()
  const fbProvider = useFB()
  const { trackAddToCart } = useGtagEvent()
  const { fetchGiftPattern } = useGift()

  const cartState: CartInfo = reactive({
    isClass: false,
    isShowAddCartModal: false,
    addCartModalSkuIds: [],
    scrollPosition: 0,
    paymentType: "credit_card_batch",
    useManualCoupon: {
      type: "",
      id: "",
      code: "",
      userCouponId: "",
    },
    quickViewProductId: "",
  })

  const cartPriceState: Omit<
    CartPriceState,
    "cartList" | "outOfSkuIds" | "outOfSkuWithGifts" | "outOfSkuWithGroupWrappings"
  > = reactive({
    subTotal: 0,
    total: 0,
    postagePrice: 0,
    totalQuantity: 0,
    discountPrice: 0,
    commissionTotal: 0,
    giftPrice: 0,
    groupWrappingPrice: 0,
    embroideryPrice: 0,
    konbiniPickupPrice: 0,
    temporaryMile: 0,
    usedCoupon: [],
    usedExchangeCoupon: false,
    totalPackageSize: 0,
    remainAmountItem: {
      remainAmount: 0,
      recommendDiscountMethod: "",
      recommendDiscountRate: 0,
      recommendDiscountFixedAmount: 0,
    },
  })

  /*
   * Ref
   */

  // 小計
  // 小計割引後
  // 税
  // 割引額合計
  // postage
  // items(明細)
  // - sku_id
  // - unit_price
  // - quantity
  // - unit_price_in_tax
  // - unit_price_in_tax_discount
  // const amountTotal = ref<Number>(0) // 税込合計
  // const amountTotalExcludeTax = ref<Number>(0) // 税抜合計
  // const amountTax = ref<Number>(0) // 税額
  const cartLine = ref<ProductAndSku[]>([])
  const calculateLoaded = ref<boolean>(false)
  // cartLine.value = store.cartList
  // アクションがあって、カートの中身が変わったら、合計金額などが変化する仕組み

  // 課題
  // useCartに責務をもたせすぎなのが問題
  // useCartのやることは
  // reactiveな金額とカートリストの表示と表示の補助。及びカートアイテムの増減によるアクション（金額計算。外部に投げるだけ）

  /*
   * Side Cart List Methods
   */
  // const disableBodyScroll = (showCart: boolean): void => {
  //   if (showCart) {
  //     document.addEventListener("touchmove", handleTouchMove, { passive: false })
  //     document.addEventListener("wheel", handleTouchMove, { passive: false })
  //   } else {
  //     document.removeEventListener("touchmove", handleTouchMove)
  //     document.removeEventListener("wheel", handleTouchMove)
  //   }
  // }

  /** カートの金額計算 */
  const calcCart = async ({
    cartList,
    isAddedToCart = true,
    deliveryMethod = "address_delivery",
  }: {
    cartList: CartItem[]
    isAddedToCart?: boolean
    deliveryMethod?: OrderDeliveryMethod
  }) => {
    if (!cartList || cartList.length <= 0) return
    calculateLoaded.value = false

    const cookie = useCookie<AuthCookie | null>(COOKIE_KEY.AUTH)

    const resValue = await $fetch<apiRes<CartPriceState>>("/api/payment/cart/price_calc", {
      method: "POST",
      headers: {
        custom_authorization: cookie.value?.accessToken ? `Bearer ${cookie.value.accessToken}` : "",
      },
      body: {
        cartList,
        payment_method: cartState.paymentType,
        use_manual_coupon: {
          type: cartState.useManualCoupon.type,
          id: cartState.useManualCoupon.id,
          code: cartState.useManualCoupon.code,
          userCouponId: cartState.useManualCoupon.userCouponId,
        },
        isAddedToCart,
        deliveryMethod,
      },
    })

    if (!resValue || resValue.status !== 200) return

    const resBody = resValue.data

    cartLine.value = resBody.cartList
    cartPriceState.subTotal = resBody.subTotal
    cartPriceState.total = resBody.total
    cartPriceState.postagePrice = resBody.postagePrice
    cartPriceState.totalQuantity = resBody.totalQuantity
    cartPriceState.discountPrice = resBody.discountPrice
    cartPriceState.commissionTotal = resBody.commissionTotal
    cartPriceState.giftPrice = resBody.giftPrice
    cartPriceState.groupWrappingPrice = resBody.groupWrappingPrice
    cartPriceState.embroideryPrice = resBody.embroideryPrice
    cartPriceState.konbiniPickupPrice = resBody.konbiniPickupPrice
    cartPriceState.temporaryMile = resBody.temporaryMile
    cartPriceState.usedCoupon = resBody.usedCoupon
    cartPriceState.usedExchangeCoupon = resBody.usedExchangeCoupon
    cartPriceState.totalPackageSize = resBody.totalPackageSize
    cartPriceState.remainAmountItem = resBody.remainAmountItem

    // 在庫切れの商品をカートから削除
    if (resBody.outOfSkuIds.length > 0) {
      store.cartList = store.cartList.filter((cartItem) => !resBody.outOfSkuIds.includes(cartItem.sku_id))
      let isGroupWrappedItem = false
      for (const skuId of resBody.outOfSkuIds) {
        // まとめてラッピングの構成商品が在庫切れの場合、ラッピング構成商品とラッピング資材をすべて削除
        const groupWrappingItem = store.cartList.find(
          (cartItem) =>
            cartItem.group_wrapping?.wrapping_sku_ids.includes(skuId) ||
            cartItem.group_wrapped_uniq_id?.includes(skuId),
        )
        if (groupWrappingItem) {
          store.cartList = store.cartList
            .filter((cartItem) => !cartItem.group_wrapping?.wrapping_sku_ids)
            .filter((cartItem) => !cartItem.group_wrapped_uniq_id)
          isGroupWrappedItem = true
        }
      }
      // TODO: toastで売り切れた商品を表示 toastもcartもinjectしているためtoastがundefinedになるのでstateをpiniaに移行するなどの対応が必要
      isGroupWrappedItem
        ? alert("在庫切れの商品を含むラッピング商品はカートから削除されました")
        : alert("在庫切れの商品はカートから削除されました")
    }

    // ギフトの在庫が切れた商品をカートから削除
    if (resBody.outOfSkuWithGifts.length > 0) {
      store.cartList = store.cartList.filter((cartItem) => {
        if (!cartItem.gift) return true
        const outOfCartItem = resBody.outOfSkuWithGifts.find((outOfSkuWithGift) => {
          return !!(
            outOfSkuWithGift.sku_id === cartItem.gift?.sku_id &&
            outOfSkuWithGift.message_sku_id === cartItem.gift?.message_sku_id &&
            outOfSkuWithGift.card_sku_id === cartItem.gift?.card_sku_id
          )
        })
        return !outOfCartItem
      })
      fetchGiftPattern()
      // TODO: toastで表示
      alert("ギフト在庫切れの商品はカートから削除されました")
    }

    // まとめてラッピング資材が在庫切れの場合、ラッピング構成商品とラッピング資材をすべて削除
    if (resBody.outOfSkuWithGroupWrappings.length > 0) {
      store.cartList = store.cartList.filter((cartItem) => !resBody.outOfSkuIds.includes(cartItem.sku_id))
      for (const giftWrapping of resBody.outOfSkuWithGroupWrappings) {
        for (const skuId of giftWrapping.wrapping_sku_ids) {
          const groupWrappingItem = store.cartList.find(
            (cartItem) =>
              cartItem.group_wrapping?.wrapping_sku_ids.includes(skuId) ||
              cartItem.group_wrapped_uniq_id?.includes(skuId),
          )
          if (groupWrappingItem) {
            store.cartList = store.cartList
              .filter((cartItem) => !cartItem.group_wrapping?.wrapping_sku_ids)
              .filter((cartItem) => !cartItem.group_wrapped_uniq_id)
          }
        }
      }
      fetchGiftPattern()
      alert("ギフト在庫切れのまとめてラッピング商品はカートから削除されました")
    }

    // filterした結果、まとめてラッピングの整合性が取れなくなった場合、cartListをリセット
    const isExistsGroupWrappingSku = store.cartList.some((cartItem) => cartItem.group_wrapping?.wrapping_sku_ids)
    const isExistsGroupWrappedSku = store.cartList.some((cartItem) => cartItem.group_wrapped_uniq_id)
    if (
      (isExistsGroupWrappingSku && !isExistsGroupWrappedSku) ||
      (!isExistsGroupWrappingSku && isExistsGroupWrappedSku)
    ) {
      console.error("group wrapping item is not matched", "resBody: ", resBody.cartList, "cartList: ", store.cartList)
      resetCart()
      alert(
        "カート内の一部商品が在庫切れとなったため、カートの内容をリセットさせていただきました。お手数ですが、改めて商品をお選びいただけますようお願いいたします",
      )
    }

    // 手動クーポンで適応バリデーションに引っかかっていた場合
    if (resBody.manualCouponError) {
      alert(`クーポンの利用条件を満たしていないためクーポンの適用が解除されました(${resBody.manualCouponError})`)
      resetManualCoupon()
    }

    // 交換クーポンだった場合
    if (resBody.usedExchangeCoupon) {
      cartState.paymentType = "cod"
    }

    calculateLoaded.value = true
    return resBody
  }

  const openAddCartModal = (skuIds: string[]): void => {
    cartState.addCartModalSkuIds = []
    cartState.addCartModalSkuIds = skuIds
    cartState.isShowAddCartModal = true
  }

  const closeAddCartModal = (): void => {
    cartState.isShowAddCartModal = false
  }

  /**
   * カートに追加
   * size, color, priceなどは入れない
   */
  const addCartItem = async (cartItem: CartItem, deliveryMethod?: OrderDeliveryMethod) => {
    store.addCartList(cartItem)
    const isAddedToCart = true

    await calcCart({
      cartList: store.cartList,
      isAddedToCart,
      deliveryMethod,
    })
    await trackingAddCart({ cartItems: [cartItem], skuIds: [cartItem.sku_id] })
  }

  /**
   * カートに一括追加
   */
  const addCartMultiItem = async ({
    cartItems,
    skuIds,
    deliveryMethod,
  }: {
    cartItems: CartItem[]
    skuIds: string[]
    deliveryMethod?: OrderDeliveryMethod
  }) => {
    const isAddedToCart = true
    for (const item of cartItems) {
      store.addCartList(item)
    }
    await calcCart({ cartList: store.cartList, isAddedToCart, deliveryMethod })
    await trackingAddCart({ cartItems, skuIds })
  }

  /**
   * カートから削除
   */
  const removeCartItem = async (args: {
    skuId: string
    gift?: SkuWithGift
    embroidery?: SkuWithEmbroidery
    groupWrapping?: SkuWithGroupWrapping
    groupWrappedUniqId?: string
    deliveryMethod?: OrderDeliveryMethod
  }) => {
    const { skuId, gift, embroidery, groupWrapping, groupWrappedUniqId, deliveryMethod } = args
    const reduceItem = removeCartList({ skuId, gift, embroidery, groupWrapping, groupWrappedUniqId })

    if (store.cartList.length >= 1) {
      await calcCart({ cartList: store.cartList, deliveryMethod })
    } else {
      cartLine.value = []
    }
    const params = {
      addCart: false,
      productId: reduceItem.product_id,
      cartLine: cartLine.value,
      cartLength: store.cartList.length,
      totalPrice: cartPriceState.total,
    }
    karteCartEvent(params)
  }

  /**
   * カートから一括削除
   */
  const removeCartMultiItem = async (
    items: {
      skuId: string
      gift?: SkuWithGift
      embroidery?: SkuWithEmbroidery
      groupWrapping?: SkuWithGroupWrapping
      groupWrappedUniqId?: string
    }[],
    deliveryMethod?: OrderDeliveryMethod,
  ) => {
    const reduceItems = []
    for (const item of items) {
      const reduceItem = removeCartList({
        skuId: item.skuId,
        gift: item.gift,
        embroidery: item.embroidery,
        groupWrapping: item.groupWrapping,
        groupWrappedUniqId: item.groupWrappedUniqId,
      })
      reduceItems.push(reduceItem)
    }

    if (store.cartList.length >= 1) {
      await calcCart({ cartList: store.cartList, deliveryMethod })
    } else {
      cartLine.value = []
    }
    const params = {
      addCart: false,
      productId: reduceItems[0].product_id,
      cartLine: cartLine.value,
      cartLength: store.cartList.length,
      totalPrice: cartPriceState.total,
    }
    karteCartEvent(params)
  }

  const removeCartList = (args: {
    skuId: string
    gift?: SkuWithGift
    embroidery?: SkuWithEmbroidery
    groupWrapping?: SkuWithGroupWrapping
    groupWrappedUniqId?: string
  }) => {
    const { skuId, gift, embroidery, groupWrapping, groupWrappedUniqId } = args
    const cloneCartList = Array.from(store.cartList)
    const index = cloneCartList.findIndex((cartItem) => {
      // まとめてラッピングのラッピング商品
      if (groupWrapping && groupWrappedUniqId) {
        return (
          cartItem.sku_id === skuId &&
          cartItem.sku_id === groupWrapping.gift.sku_id &&
          cartItem.group_wrapping?.wrapping_sku_ids.every((skuId) => groupWrapping.wrapping_sku_ids.includes(skuId)) &&
          getGroupWrappingUniqId(groupWrapping) === groupWrappedUniqId
        )
      }
      // まとめてラッピングされている商品
      else if (!gift && groupWrappedUniqId)
        return (
          cartItem.sku_id === skuId &&
          cartItem.group_wrapped_uniq_id === groupWrappedUniqId &&
          !cartItem.gift &&
          cartItem.embroidery?.text === embroidery?.text &&
          cartItem.embroidery?.color === embroidery?.color &&
          cartItem.embroidery?.font === embroidery?.font &&
          cartItem.embroidery?.position === embroidery?.position
        )
      // SKU＋ラッピングなし＋刺繍が一致
      else if (!gift)
        return (
          cartItem.sku_id === skuId &&
          !cartItem.group_wrapped_uniq_id &&
          !cartItem.gift &&
          cartItem.embroidery?.text === embroidery?.text &&
          cartItem.embroidery?.color === embroidery?.color &&
          cartItem.embroidery?.font === embroidery?.font &&
          cartItem.embroidery?.position === embroidery?.position
        )
      // SKU＋カード＋刺繍が一致
      else if (!gift.sku_id && !gift?.message && gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          !cartItem.gift.sku_id &&
          !cartItem.gift.message &&
          cartItem.gift.card === gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋メッセージ＋カード＋刺繍が一致
      else if (gift?.message && gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          cartItem.gift.message === gift.message &&
          cartItem.gift.card === gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋メッセージ＋刺繍が一致
      else if (gift?.message && !gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          cartItem.gift.message === gift.message &&
          !cartItem.gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋カード＋刺繍が一致
      else if (!gift?.message && gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          !cartItem.gift.message &&
          cartItem.gift.card === gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋刺繍が一致
      else if (!gift?.message && !gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          !cartItem.gift.message &&
          !cartItem.gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // その他
      else
        return (
          cartItem.sku_id === skuId &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
    })
    const reduceItem = store.cartList[index]
    cloneCartList.splice(index, 1)
    store.cartList = cloneCartList
    return reduceItem
  }

  const getCartCalcByPaymentType = (paymentType: string, deliveryMethod?: OrderDeliveryMethod) => {
    cartState.paymentType = paymentType
    calcCart({ cartList: store.cartList, deliveryMethod })
  }

  /** 手動クーポンが適応できるか、できる場合は適応する関数 */
  const checkAndUseManualCoupon = async (
    manualCoupon: UseManualCoupon,
    deliveryMethod?: OrderDeliveryMethod,
  ): Promise<string | undefined> => {
    const cookie = useCookie<AuthCookie | null>(COOKIE_KEY.AUTH)
    const res = await $fetch<ValidatedCoupon>("/api/payment/cart/fetch_coupon", {
      method: "POST",
      headers: {
        custom_authorization: cookie.value?.accessToken ? `Bearer ${cookie.value.accessToken}` : "",
      },
      body: {
        useManualCoupon: manualCoupon,
        cartList: store.cartList,
        subTotal: cartPriceState.subTotal,
      },
    })
    if (res.status !== 200 || !res.body) {
      return res?.body.message || "クーポンの取得に失敗しました"
    }

    cartState.useManualCoupon = manualCoupon
    // コード入力の下に注釈あるためモーダルで二重表示する必要なさそうなので廃止
    // 廃止後お問い合わせを確認して問題なさそうであれば削除
    // if (
    //   !res.body.canCombinedCoupon &&
    //   cartPriceState.usedCoupon.length > 0 &&
    //   !(await proceed(`他のクーポンとの併用はできません。\n現在適用中のクーポンを上書きしますか？`))
    // )
    //   return ""

    await calcCart({ cartList: store.cartList, deliveryMethod })
  }

  const resetManualCoupon = () => {
    cartState.useManualCoupon = {
      type: "",
      id: "",
      code: "",
      userCouponId: "",
    }
  }

  /** 決済手段と利用クーポン情報はorder/newから離れたらリセットする(今後は維持する形をとっても良いが自動クーポンが適応されなくなってしまうので考える必要あり) */
  const resetPaymentTypeAndCoupon = (deliveryMethod: OrderDeliveryMethod) => {
    cartState.paymentType = "credit_card_batch"
    cartState.useManualCoupon = {
      type: "",
      id: "",
      code: "",
      userCouponId: "",
    }
    calcCart({ cartList: store.cartList, deliveryMethod })
  }

  const resetCart = () => {
    cartLine.value = []
    cartPriceState.subTotal = 0
    cartPriceState.total = 0
    cartPriceState.postagePrice = 0
    cartPriceState.totalQuantity = 0
    cartPriceState.discountPrice = 0
    cartPriceState.commissionTotal = 0
    cartPriceState.giftPrice = 0
    cartPriceState.groupWrappingPrice = 0
    cartPriceState.temporaryMile = 0
    cartPriceState.usedCoupon = []
    cartPriceState.usedExchangeCoupon = false
    cartPriceState.totalPackageSize = 0
    cartState.paymentType = ""
    resetManualCoupon()
    store.cartList = []
  }

  // 以下 return しない関数
  /** Facebookイベント処理 */
  const sendFacebookEvents = async (
    addToCartId: string,
    contentName: string,
    mapProductName: string[],
    userAgent: string,
    url: string,
  ): Promise<void> => {
    fbProvider.FBPixelEventPush(addToCartId, "product_group", "AddToCart", contentName, mapProductName)
    await fbProvider.FBCApiEventPush(addToCartId, "AddToCart", contentName, userAgent, url)
  }

  /** Karteイベント処理 */
  const sendKarteEvent = (skuIds: string[]): void => {
    const params = {
      addCart: true,
      productId: "", // 後から設定
      cartLine: cartLine.value,
      cartLength: store.cartList.length,
      totalPrice: cartPriceState.total,
    }

    skuIds.forEach((skuId) => {
      const productId = cartLine.value.find((cart) => cart.sku.document_id === skuId)?.product.document_id || ""
      params.productId = productId
      karteCartEvent(params)
    })
  }

  /** カート追加のイベント送信 */
  const trackingAddCart = async ({ cartItems, skuIds }: { cartItems: CartItem[]; skuIds: string[] }): Promise<void> => {
    if (config.public.APP_ENV !== "production") return

    // 共通データ
    const productNameMap = new Map(cartItems.map((cartItem) => [cartItem.product_id, cartItem.product_name || ""]))
    const skus = cartLine.value.map((cart) => cart.sku).filter((sku) => skuIds.includes(sku.document_id))
    const mapProductName = Array.from(productNameMap.values())
    const unixTime = getNowUnixTime()
    const url = window.location.href
    const userAgent = window.navigator.userAgent || ""

    // メインループ
    await forEachAsync(skuIds, async (skuId) => {
      const productId = cartLine.value.find((cart) => cart.sku.document_id === skuId)?.product.document_id || ""
      const addToCartId = `${productId}${unixTime}`
      const contentName = productNameMap.get(productId) || ""

      // Facebookイベント送信
      await sendFacebookEvents(addToCartId, contentName, mapProductName, userAgent, url)
    })

    // Karteイベント送信
    sendKarteEvent(skuIds)

    // GAイベント送信
    trackAddToCart(skus)

    // TikTokイベント送信
    trackTtqAddCart(skus)
  }

  /*
   * Calculator Logic
   */

  return {
    cartState,
    cartPriceState,
    openAddCartModal,
    closeAddCartModal,
    addCartItem,
    addCartMultiItem,
    removeCartItem,
    removeCartMultiItem,
    calcCart,
    cartLine,
    calculateLoaded,
    getCartCalcByPaymentType,
    checkAndUseManualCoupon,
    resetManualCoupon,
    resetCart,
    resetPaymentTypeAndCoupon,
    // amountTotal,
    // amountTotalExcludeTax,
    // amountTax,
    // cartLine,
  }
}

export type UseCartStore = ReturnType<typeof provideUseCart>

const UseCartKey: InjectionKey<UseCartStore> = Symbol("UseCartStore")

export default UseCartKey

export const defineCart = (): void => {
  return provide(UseCartKey, provideUseCart())
}

export const useCart = () => {
  const state = inject(UseCartKey)
  if (!state) {
    throw new Error("NO Global Key")
  }

  return state
}
