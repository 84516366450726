// doc https://stripe.com/docs/declines/codes#stripe-decline-codes
interface ErrorReasons {
  [key: string]: string
}
export const STRIPE_ERROR_REASON: ErrorReasons = {
  authentication_required:
    "この取引には3Dセキュアなどの認証が必要なため、カードは拒否されました。3Dセキュアの設定をしてください",
  approve_with_id:
    "再度支払いをお試しください。それでも失敗する場合はカード会社に問い合わせるか別の支払い方法をお試しください",
  call_issuer: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  card_not_supported: "このカードはサポートされていません。別のカードをお試しください",
  card_velocity_exceeded:
    "カードの残高、クレジットの利用限度額、または取引限度額を超えています。別のカードをお試しください",
  currency_not_supported: "このカードは指定された通貨に対応していません。別のカードをお試しください",
  do_not_honor: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  do_not_try_again: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  duplicate_transaction:
    "金額とクレジットカード情報がまったく同じ取引がごく最近送信されているため拒否されました。別のカードをお試しください",
  expired_card: "カードの有効期限が切れています",
  fraudulent: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  generic_decline: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  incorrect_number: "カード番号が正しくありません",
  incorrect_cvc: "セキュリティコードが正しくありません",
  incorrect_pin: "入力されたPINが正しくありません",
  incorrect_zip: "郵便番号が正しくありません",
  insufficient_funds: "カードの残高が不足しているため購入を完了できません。別のカードをお試しください",
  invalid_account: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  invalid_amount: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  invalid_cvc: "セキュリティコードが正しくありません",
  invalid_expiry_month: "有効期限の月が無効です",
  invalid_expiry_year: "有効期限の年が無効です",
  invalid_number: "カード番号が正しくありません",
  invalid_pin: "入力されたPINが正しくありません",
  issuer_not_available:
    "再度支払いをお試しください。それでも失敗する場合はカード会社に問い合わせるか別の支払い方法をお試しください",
  lost_card: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  merchant_blacklist: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  new_account_information_available: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  no_action_taken: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  not_permitted: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  pickup_card: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  pin_try_exceeded: "別のカードまたは別のお支払い方法をお試しください",
  processing_error: "再度支払いをお試しください。それでも失敗する場合は時間を空けて再度お試しください",
  reenter_transaction:
    "再度支払いをお試しください。それでも失敗する場合はカード会社に問い合わせるか別のカードをお試しください",
  restricted_card: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  revocation_of_all_authorizations: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  revocation_of_authorization: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  security_violation: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  service_not_allowed: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  stolen_card: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  stop_payment_order: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  testmode_decline: "こちらはテスト用のカードです。別のカードをお試しください",
  transaction_not_allowed: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
  try_again_later: "再度支払いをお試しください。それでも失敗する場合は時間を空けて再度お試しください",
  withdrawal_count_limit_exceeded:
    "こちらのカードは残高またはクレジットの利用限度額を超えています。別のカードをお試しください",
  card_declined: "決済に失敗しました。カード会社に問い合わせるか別のカードをお試しください",
}

export const ASSIST_FIRST_TIME_ERROR: string[] = [
  "authentication_required",
  "approve_with_id",
  "call_issuer",
  "card_not_supported",
  "currency_not_supported",
  "do_not_try_again",
  "fraudulent",
  "invalid_account",
  "invalid_amount",
  "issuer_not_available",
  "lost_card",
  "merchant_blacklist",
  "new_account_information_available",
  "no_action_taken",
  "not_permitted",
  "pickup_card",
  "pin_try_exceeded",
  "processing_error",
  "reenter_transaction",
  "restricted_card",
  "revocation_of_all_authorizations",
  "revocation_of_authorization",
  "security_violation",
  "service_not_allowed",
  "stolen_card",
  "stop_payment_order",
  "transaction_not_allowed",
]

export const ASSIST_THIRD_TIME_ERROR: string[] = ["try_again_later", "card_declined", "generic_decline", "do_not_honor"]
